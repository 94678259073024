import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Table from './Table';
import TradeTable from './TradeTable';
import './Derivatives.css';
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import Tab from '@mui/material/Tab';
import LeftPanel from './LeftPanel';
import {
  Avatar,
  Button,
  CircularProgress,
  Paper,
  Typography
} from '@mui/material';
import { useSelector } from 'react-redux';
import url from '../../../serverUrl';
import { api as axios } from '../../../api';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { useParams } from 'react-router-dom';
import MyTradeTable from './myTrade';
import { Backdrop } from '@mui/material';
const OrderTypeEnum = {
  SELL_MARKET_ORDER: 'SELL MARKET ORDER',
  BUY_MARKET_ORDER: 'BUY MARKET ORDER',
  BUY_LIMIT_ORDER: 'BUY LIMIT ORDER',
  SELL_LIMIT_ORDER: 'SELL LIMIT ORDER',
  TOKEN_SWAP: 'TOKEN SWAP'
};
const orderTypeList = [
  OrderTypeEnum.BUY_LIMIT_ORDER,
  OrderTypeEnum.BUY_MARKET_ORDER,
  OrderTypeEnum.SELL_LIMIT_ORDER,
  OrderTypeEnum.SELL_MARKET_ORDER,
  OrderTypeEnum.TOKEN_SWAP
];
const SwapSystem = ({
  token,
  PecuPrice,
  projectTokens,
  holdingTokens,
  bid,
  ask
}) => {
  const [swapType, setSwapType] = useState(OrderTypeEnum.BUY_MARKET_ORDER);
  const [swapSymbol, setSwapSymbol] = useState('');
  const [swapAmount, setSwapAmount] = useState(1);
  const [limitPrice, setLimitPrice] = useState(1);
  const [confirmationBox, setConfirmationBox] = useState(false);
  const [loading, setLoading] = useState(false);
  const [bidPrice, setBidPrice] = useState(0);
  const [askPrice, setAskPrice] = useState(0);
  const [scheduleTime, setScheduleTime] = useState('');
  const [partialOrder, setPartialOrder] = useState(false);

  useEffect(() => {
    if (bid.length > 0 && bid[0].price) {
      setBidPrice(bid[0].price);
    }
  }, [bid]);
  useEffect(() => {
    if (ask.length > 0 && ask[0].price) {
      setAskPrice(ask[0]?.price);
    }
  }, [ask]);
  const placeOrderConfirmed = async () => {
    let data = localStorage.getItem('hootdex_secretcookie_wallet');

    if (data) {
      const user = JSON.parse(data);
      const userId = user.id;
      const pub_key = user.pub_key;
      const symbol = token?.symbol || token?.token_symbol;
      const quantity = swapAmount;

      if (swapType === OrderTypeEnum.BUY_MARKET_ORDER) {
        const payload = {
          symbol,
          askPrice: askPrice,
          quantity,
          userId,
          pub_key
        };
        const response = await axios.post(`${url}/crypto/marketOrder`, payload);
        setConfirmationBox(false);
        setSwapAmount(1);
        alert(JSON.stringify(response.data));
        return;
      }
      if (swapType === OrderTypeEnum.SELL_MARKET_ORDER) {
        //place bid

        const payload = {
          symbol,
          askPrice: askPrice,
          quantity,
          userId,
          pub_key
        };
        try {
          const response = await axios.post(
            `${url}/crypto/sellmarketOrder`,
            payload
          );
          setConfirmationBox(false);
          setAskPrice(0);
          setScheduleTime('');
          setSwapAmount(1);
          setLimitPrice(1);
          alert(JSON.stringify(response.data));
        } catch (error) {
          // console.log(error);
          setConfirmationBox(false);
          setAskPrice(0);
          setScheduleTime('');
          setSwapAmount(1);
          setLimitPrice(1);
          alert(JSON.stringify(error));
        }
      } else if (swapType.includes('SELL')) {
        //place bid

        const payload = {
          symbol,
          bidPrice: ['SELL LIMIT ORDER'].includes(swapType)
            ? limitPrice
            : bidPrice,
          quantity,
          userId,
          pub_key,
          swapType,
          scheduleTime
        };
        try {
          const response = await axios.post(`${url}/crypto/placeAsk`, payload);
          setConfirmationBox(false);
          setAskPrice(0);
          setScheduleTime('');
          setSwapAmount(1);
          setLimitPrice(1);
          alert(JSON.stringify(response.data));
        } catch (error) {
          // console.log(error);
          setConfirmationBox(false);
          setAskPrice(0);
          setScheduleTime('');
          setSwapAmount(1);
          setLimitPrice(1);
          alert(JSON.stringify(error));
        }
      } else if (swapType.includes('BUY')) {
        //place ask
        const payload = {
          symbol,
          askPrice: ['BUY LIMIT ORDER'].includes(swapType)
            ? limitPrice
            : askPrice,
          quantity,
          userId,
          pub_key,
          swapType,
          scheduleTime,
          partialOrder
        };
        try {
          setLoading(true);
          const response = await axios.post(`${url}/crypto/placeBid`, payload);
          setLoading(false);
          setConfirmationBox(false);
          setAskPrice(0);
          setScheduleTime('');
          setSwapAmount(1);
          setLimitPrice(1);
          alert(JSON.stringify(response.data));
        } catch (error) {
          setLoading(false);
          setConfirmationBox(false);
          setAskPrice(0);
          setScheduleTime('');
          setSwapAmount(1);
          setLimitPrice(1);
          alert(JSON.stringify(error));
        }
      }
    } else {
      alert('Please Login');
    }
  };
  const placeOrder = () => {
    setConfirmationBox(true);
  };
  const swapTokens = async (token, tokenSwapTo, amount) => {
    let data = localStorage.getItem('hootdex_secretcookie_wallet');

    if (data) {
      const user = JSON.parse(data);
      const userId = user.id;
      const pub_key = user.pub_key;
      const tokenSwapFrom = token.identitySymbol || token?.token_symbol;
      try {
        const response = await axios.post(`${url}/wallet/swap`, {
          tokenSwapFrom,
          tokenSwapTo,
          amount,
          userId,
          pub_key
        });

        alert(JSON.stringify(response.data));
      } catch (error) {
        // console.log(error);
        alert(JSON.stringify(error));
      }
    } else {
      alert('Please Login');
    }
  };

  if (loading) {
    return (
      <Box
        className="border"
        sx={{
          backdropFilter: 'blur(5px)',
          p: 3,

          height: 'max-content'
        }}
      >
        <Grid
          container
          spacing={{ xs: 1, md: 2 }}
          style={{ flexDirection: 'column' }}
        >
          <Grid item xs={12} md={4}>
            <Paper
              style={{
                textAlign: 'center',
                backgroundColor: '#00071a',
                minWidth: '300px'
              }}
              className="border tShadow"
            >
              <span>
                <p
                  className="fontS10"
                  style={{ color: 'white', fontSize: '20px' }}
                >
                  Transaction in progress
                </p>
              </span>
            </Paper>
          </Grid>

          <Grid item xs={12}>
            <div style={{ textAlign: 'center' }}>
              <Button
                className="border"
                variant="contained"
                sx={{
                  color: 'white',
                  backgroundColor: '#01402b',
                  width: '100%'
                }}
              >
                Please Wait
              </Button>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div style={{ textAlign: 'center', minHeight: '350px' }}>
              <CircularProgress />
            </div>
          </Grid>
        </Grid>
      </Box>
    );
  }

  if (confirmationBox) {
    return (
      <Box
        className="border"
        sx={{
          backdropFilter: 'blur(5px)',
          p: 3,

          height: 'max-content'
        }}
      >
        <Grid
          container
          spacing={{ xs: 1, md: 2 }}
          style={{ flexDirection: 'column' }}
        >
          <Grid item xs={12} md={4}>
            <Paper
              style={{
                textAlign: 'center',
                backgroundColor: '#00071a',
                minWidth: '300px'
              }}
              className="border tShadow"
            >
              <span>
                <p
                  className="fontS10"
                  style={{ color: 'white', fontSize: '20px' }}
                >
                  Confirm Swap Order
                </p>
              </span>
            </Paper>
          </Grid>

          <Grid item xs={12}>
            <div style={{ textAlign: 'center' }}>
              <Button
                className="border"
                variant="contained"
                sx={{
                  color: 'white',
                  backgroundColor: '#01402b',
                  width: '100%'
                }}
                onClick={placeOrderConfirmed}
              >
                Confirm
              </Button>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div style={{ textAlign: 'center' }}>
              <Button
                className="border"
                variant="contained"
                sx={{
                  color: 'white',
                  backgroundColor: 'red',
                  width: '100%'
                }}
                onClick={() => {
                  setConfirmationBox(false);
                }}
              >
                Cancel
              </Button>
            </div>
          </Grid>
        </Grid>
      </Box>
    );
  }
  if (swapType === OrderTypeEnum.SELL_MARKET_ORDER) {
    return (
      <Box
        className="border"
        sx={{
          backdropFilter: 'blur(5px)',
          p: 3,
          height: 'max-content'
        }}
      >
        <span
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-evenly'
          }}
        >
          <select
            style={{
              width: '100%',
              padding: '12px',
              backgroundColor: 'black',
              color: 'orange',
              fontWeight: 'bold',
              border: 'none',
              outline: 'none',
              marginBottom: '1rem'
            }}
            value={swapType}
            onChange={(e) => {
              setSwapType(e.target.value);
            }}
          >
            {orderTypeList.map((e, i) => (
              <option value={e} key={i}>
                {e}
              </option>
            ))}
          </select>
        </span>
        <span
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-evenly'
          }}
        >
          <span
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center'
            }}
          >
            <Avatar
              className="rounded"
              alt="token logo"
              style={{
                width: '16px',
                height: '16px',
                color: 'rgb(86, 90, 105)',
                marginRight: '8px'
              }}
              src={token?.logo ?? token?.image}
            />{' '}
            <p className="fontS10" style={{ color: 'grey', fontSize: '12px' }}>
              1 {token?.identitySymbol ?? token?.token_symbol}
            </p>
          </span>
          <p className="fontS10" style={{ color: 'grey', fontSize: '12px' }}>
            {'='}
          </p>
          <span
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center'
            }}
          >
            <p className="fontS15" style={{ color: 'grey', fontSize: '12px' }}>
              {bidPrice?.toFixed(5)?.toLocaleString('en-US')} USD
            </p>
          </span>
        </span>
        <span
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-evenly'
          }}
        >
          <span
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center'
            }}
          >
            <Avatar
              className="rounded"
              alt="token logo"
              style={{
                width: '16px',
                height: '16px',
                color: 'rgb(86, 90, 105)',
                marginRight: '8px'
              }}
              src={`https://api.hootdex.net/hootdex/images/pecuCoin.png`}
            />{' '}
            <p className="fontS10" style={{ color: 'grey', fontSize: '12px' }}>
              1 {'PECU'}
            </p>
          </span>
          <p className="fontS10" style={{ color: 'grey', fontSize: '12px' }}>
            {'='}
          </p>
          <span
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center'
            }}
          >
            <p className="fontS15" style={{ color: 'grey', fontSize: '12px' }}>
              {PecuPrice?.toFixed(5)?.toLocaleString('en-US')} USD
            </p>
          </span>
        </span>
        <br></br>
        <Grid
          container
          spacing={{ xs: 1, md: 2 }}
          style={{ flexDirection: 'column' }}
        >
          <Grid item xs={12} md={4}>
            <Paper
              style={{
                textAlign: 'center',
                backgroundColor: '#00071a',
                minWidth: '300px'
              }}
              className="border tShadow"
            >
              {' '}
              <div
                style={{
                  color: 'white',
                  wordWrap: 'break-word'
                }}
              >
                <Avatar
                  className="rounded"
                  alt="token logo"
                  style={{
                    width: '28px',
                    height: '28px',
                    color: 'rgb(86, 90, 105)',
                    margin: '8px'
                  }}
                  src={token?.logo || token?.image}
                />{' '}
                <div
                  className="rounded center-width tUpper"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    color: 'grey',
                    fontSize: '12px'
                  }}
                >
                  <h5>
                    Enter Amount ({token?.identitySymbol || token?.token_symbol}
                    )
                  </h5>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    padding: '5px'
                  }}
                >
                  <input
                    autoFocus
                    type={'number'}
                    style={{
                      outline: 'none',
                      backgroundColor: 'white',
                      width: '95%',
                      padding: '5px',
                      fontSize: '1rem'
                    }}
                    value={swapAmount}
                    onChange={(e) => setSwapAmount(e.target.value)}
                  />

                  <span
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between'
                    }}
                  ></span>
                </div>
              </div>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
            <Paper
              style={{
                textAlign: 'center',
                backgroundColor: '#00071a',
                minWidth: '300px'
              }}
              className="border tShadow"
            >
              <div
                className="rounded center-width tUpper"
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  color: 'grey',
                  fontSize: '13px'
                }}
              >
                <h5>Estimated Total</h5>
                {/* <h5>{(swapAmount * limitPrice) / PecuPrice} PECU</h5> */}
              </div>
              <div
                style={{
                  color: 'white',
                  wordWrap: 'break-word'
                }}
              >
                {' '}
                <Avatar
                  className="rounded"
                  alt="token logo"
                  style={{
                    width: '25px',
                    height: '25px',
                    color: 'rgb(86, 90, 105)',
                    marginRight: '8px'
                  }}
                  src={`https://api.hootdex.net/hootdex/images/pecuCoin.png`}
                />
                <div
                  className="rounded center-width tUpper"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    color: 'grey',
                    fontSize: '13px'
                  }}
                >
                  <h5>Total (PECU)</h5>
                  {/* <h5>{(swapAmount * limitPrice) / PecuPrice} PECU</h5> */}
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    padding: '5px'
                  }}
                >
                  <input
                    autoFocus
                    type={'number'}
                    style={{
                      outline: 'none',
                      backgroundColor: 'white',
                      width: '95%',
                      padding: '5px',
                      fontSize: '1rem'
                    }}
                    disabled
                    value={(swapAmount * bidPrice) / PecuPrice}
                  />
                </div>
                <div
                  className="rounded center-width tUpper"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    color: 'grey',
                    fontSize: '13px'
                  }}
                >
                  <h5>Total (USD)</h5>
                  {/* <h5>{swapAmount * limitPrice} USD</h5> */}
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    padding: '5px'
                  }}
                >
                  <input
                    autoFocus
                    type={'number'}
                    style={{
                      outline: 'none',
                      backgroundColor: 'white',
                      width: '95%',
                      padding: '5px',
                      fontSize: '1rem'
                    }}
                    disabled
                    value={(swapAmount * bidPrice)?.toFixed(5)}
                  />
                </div>
              </div>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <div style={{ textAlign: 'center' }}>
              <Button
                onClick={placeOrder}
                className="border"
                variant="contained"
                sx={{
                  color: 'white',
                  backgroundColor: '#01402b',
                  width: '100%'
                }}
              >
                Place Sell Order
              </Button>
            </div>
          </Grid>
        </Grid>
      </Box>
    );
  }
  if (swapType === OrderTypeEnum.BUY_MARKET_ORDER) {
    return (
      <Box
        className="border"
        sx={{
          backdropFilter: 'blur(5px)',
          p: 3,

          height: 'max-content'
        }}
      >
        <span
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-evenly'
          }}
        >
          <select
            style={{
              width: '100%',
              padding: '12px',
              backgroundColor: 'black',
              color: 'orange',
              fontWeight: 'bold',
              border: 'none',
              outline: 'none',
              marginBottom: '1rem'
            }}
            value={swapType}
            onChange={(e) => {
              setSwapType(e.target.value);
            }}
          >
            {orderTypeList.map((e, i) => (
              <option value={e} key={i}>
                {e}
              </option>
            ))}
          </select>
        </span>
        <span
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-evenly'
          }}
        >
          <span
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center'
            }}
          >
            <Avatar
              className="rounded"
              alt="token logo"
              style={{
                width: '16px',
                height: '16px',
                color: 'rgb(86, 90, 105)',
                marginRight: '8px',
                color: 'gray'
              }}
              src={token?.logo || token?.image}
            />{' '}
            <p className="fontS10" style={{ color: 'grey', fontSize: '12px' }}>
              1 {token?.identitySymbol || token?.token_symbol}
            </p>
          </span>
          <p className="fontS10" style={{ color: 'grey', fontSize: '12px' }}>
            {'='}
          </p>
          <span
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center'
            }}
          >
            <p className="fontS15" style={{ color: 'grey', fontSize: '12px' }}>
              {askPrice?.toFixed(5)?.toLocaleString('en-US')} USD
            </p>
          </span>
        </span>
        <span
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-evenly'
          }}
        >
          <span
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center'
            }}
          >
            <Avatar
              className="rounded"
              alt="token logo"
              style={{
                width: '16px',
                height: '16px',
                color: 'rgb(86, 90, 105)',
                marginRight: '8px',
                color: 'gray'
              }}
              src={`https://api.hootdex.net/hootdex/images/pecuCoin.png`}
            />{' '}
            <p className="fontS10" style={{ color: 'grey', fontSize: '12px' }}>
              1 {'PECU'}
            </p>
          </span>
          <p className="fontS10" style={{ color: 'grey', fontSize: '12px' }}>
            {'='}
          </p>
          <span
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center'
            }}
          >
            <p className="fontS15" style={{ color: 'grey', fontSize: '12px' }}>
              {PecuPrice?.toFixed(5)?.toLocaleString('en-US')} USD
            </p>
          </span>
        </span>
        <br></br>
        <Grid
          container
          spacing={{ xs: 1, md: 2 }}
          style={{ flexDirection: 'column' }}
        >
          <Grid item xs={12} md={4}>
            <Paper
              style={{
                textAlign: 'center',
                backgroundColor: '#00071a',
                minWidth: '300px'
              }}
              className="border tShadow"
            >
              {' '}
              <div
                style={{
                  color: 'white',
                  wordWrap: 'break-word'
                }}
              >
                <Avatar
                  className="rounded"
                  alt="token logo"
                  style={{
                    width: '28px',
                    height: '28px',
                    color: 'rgb(86, 90, 105)',
                    margin: '8px'
                  }}
                  src={token?.logo || token?.image}
                />{' '}
                <div
                  className="rounded center-width tUpper"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    color: 'grey',
                    fontSize: '12px'
                  }}
                >
                  <h5>
                    Enter Amount ({token?.identitySymbol || token?.token_symbol}
                    )
                  </h5>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    padding: '5px'
                  }}
                >
                  <input
                    autoFocus
                    type={'number'}
                    style={{
                      outline: 'none',
                      backgroundColor: 'white',
                      width: '95%',
                      padding: '5px',
                      fontSize: '1rem'
                    }}
                    value={swapAmount}
                    onChange={(e) => setSwapAmount(e.target.value)}
                  />

                  <span
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between'
                    }}
                  ></span>
                </div>
              </div>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
            <Paper
              style={{
                textAlign: 'center',
                backgroundColor: '#00071a',
                minWidth: '300px'
              }}
              className="border tShadow"
            >
              <div
                className="rounded center-width tUpper"
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  color: 'grey',
                  fontSize: '13px'
                }}
              >
                <h5>Estimated Total</h5>
                {/* <h5>{(swapAmount * limitPrice) / PecuPrice} PECU</h5> */}
              </div>
              <div
                style={{
                  color: 'white',
                  wordWrap: 'break-word'
                }}
              >
                {' '}
                <Avatar
                  className="rounded"
                  alt="token logo"
                  style={{
                    width: '25px',
                    height: '25px',
                    color: 'rgb(86, 90, 105)',
                    marginRight: '8px'
                  }}
                  src={`https://api.hootdex.net/hootdex/images/pecuCoin.png`}
                />
                <div
                  className="rounded center-width tUpper"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    color: 'grey',
                    fontSize: '13px'
                  }}
                >
                  <h5>Total (PECU)</h5>
                  {/* <h5>{(swapAmount * limitPrice) / PecuPrice} PECU</h5> */}
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    padding: '5px'
                  }}
                >
                  <input
                    autoFocus
                    type={'number'}
                    style={{
                      outline: 'none',
                      backgroundColor: 'white',
                      width: '95%',
                      padding: '5px',
                      fontSize: '1rem'
                    }}
                    disabled
                    value={(swapAmount * askPrice) / PecuPrice}
                  />
                </div>
                <div
                  className="rounded center-width tUpper"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    color: 'grey',
                    fontSize: '13px'
                  }}
                >
                  <h5>Total (USD)</h5>
                  {/* <h5>{swapAmount * limitPrice} USD</h5> */}
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    padding: '5px'
                  }}
                >
                  <input
                    autoFocus
                    type={'number'}
                    style={{
                      outline: 'none',
                      backgroundColor: 'white',
                      width: '95%',
                      padding: '5px',
                      fontSize: '1rem'
                    }}
                    disabled
                    value={(swapAmount * askPrice)?.toFixed(5)}
                  />
                </div>
              </div>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <div style={{ textAlign: 'center' }}>
              <Button
                onClick={placeOrder}
                className="border"
                variant="contained"
                sx={{
                  color: 'white',
                  backgroundColor: '#01402b',
                  width: '100%'
                }}
              >
                Place Buy Order
              </Button>
            </div>
          </Grid>
        </Grid>
      </Box>
    );
  }
  if (swapType === OrderTypeEnum.BUY_LIMIT_ORDER) {
    return (
      <Box
        className="border"
        sx={{ backdropFilter: 'blur(5px)', p: 3, height: 'max-content' }}
      >
        <span
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-evenly'
          }}
        >
          <select
            style={{
              width: '100%',
              padding: '12px',
              backgroundColor: 'black',
              color: 'orange',
              fontWeight: 'bold',
              border: 'none',
              outline: 'none',
              marginBottom: '1rem'
            }}
            value={swapType}
            onChange={(e) => {
              setSwapType(e.target.value);
            }}
          >
            {orderTypeList.map((e, i) => (
              <option value={e} key={i}>
                {e}
              </option>
            ))}
          </select>
        </span>
        <span
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-evenly'
          }}
        >
          <span
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center'
            }}
          >
            <Avatar
              className="rounded"
              alt="token logo"
              style={{
                width: '16px',
                height: '16px',
                color: 'rgb(86, 90, 105)',
                marginRight: '8px',
                color: 'gray'
              }}
              src={token?.logo || token?.image}
            />{' '}
            <p className="fontS10" style={{ color: 'grey', fontSize: '12px' }}>
              1 {token?.identitySymbol || token?.token_symbol}
            </p>
          </span>
          <p className="fontS10" style={{ color: 'grey', fontSize: '12px' }}>
            {'='}
          </p>
          <span
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center'
            }}
          >
            <p className="fontS15" style={{ color: 'grey', fontSize: '12px' }}>
              {askPrice?.toFixed(5)?.toLocaleString('en-US')} USD USD
            </p>
          </span>
        </span>
        <span
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-evenly'
          }}
        >
          <span
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center'
            }}
          >
            <Avatar
              className="rounded"
              alt="token logo"
              style={{
                width: '16px',
                height: '16px',
                color: 'rgb(86, 90, 105)',
                marginRight: '8px',
                color: 'gray'
              }}
              src={`https://api.hootdex.net/hootdex/images/pecuCoin.png`}
            />{' '}
            <p className="fontS10" style={{ color: 'grey', fontSize: '12px' }}>
              1 {'PECU'}
            </p>
          </span>
          <p className="fontS10" style={{ color: 'grey', fontSize: '12px' }}>
            {'='}
          </p>
          <span
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center'
            }}
          >
            <p className="fontS15" style={{ color: 'grey', fontSize: '12px' }}>
              {PecuPrice?.toFixed(5)?.toLocaleString('en-US')} USD
            </p>
          </span>
        </span>
        <br></br>
        <Grid
          container
          spacing={{ xs: 1, md: 2 }}
          style={{ flexDirection: 'column' }}
        >
          <Grid item xs={12} md={4}>
            <Paper
              style={{
                textAlign: 'center',
                backgroundColor: '#00071a',
                minWidth: '300px'
              }}
              className="border tShadow"
            >
              {' '}
              <div
                style={{
                  color: 'white',
                  wordWrap: 'break-word'
                }}
              >
                <Avatar
                  className="rounded"
                  alt="token logo"
                  style={{
                    width: '28px',
                    height: '28px',
                    color: 'rgb(86, 90, 105)',
                    margin: '8px'
                  }}
                  src={token?.logo || token?.image}
                />{' '}
                {/* <div
                  className="rounded center-width tUpper"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    color: 'grey',
                    fontSize: '12px'
                  }}
                >
                  <h5>Price({'USD'})</h5>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    padding: '5px'
                  }}
                >
                  <input
                    autoFocus
                    type={'number'}
                    style={{
                      outline: 'none',
                      backgroundColor: 'white',
                      width: '95%',
                      padding: '5px',
                      fontSize: '1rem'
                    }}
                    disabled
                    value={token?.currentPrice
                      ?.toFixed(5)
                      ?.toLocaleString('en-US')}
                    onChange={(e) => setSwapAmount(e.target.value)}
                  />

                  <span
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between'
                    }}
                  ></span>
                </div> */}
                <div
                  className="rounded center-width tUpper"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    color: 'grey',
                    fontSize: '12px'
                  }}
                >
                  <h5>Limit Price({'USD'})</h5>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    padding: '5px'
                  }}
                >
                  <input
                    autoFocus
                    type={'number'}
                    style={{
                      outline: 'none',
                      backgroundColor: 'white',
                      width: '95%',
                      padding: '5px',
                      fontSize: '1rem'
                    }}
                    value={limitPrice}
                    onChange={(e) => setLimitPrice(e.target.value)}
                  />

                  <span
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between'
                    }}
                  ></span>
                </div>
                <div
                  className="rounded center-width tUpper"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    color: 'grey',
                    fontSize: '12px'
                  }}
                >
                  <h5>
                    Time of Limit Order(
                    {token?.identitySymbol || token?.token_symbol})
                  </h5>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    padding: '5px'
                  }}
                >
                  <input
                    autoFocus
                    type={'datetime-local'}
                    style={{
                      outline: 'none',
                      backgroundColor: 'white',
                      width: '95%',
                      padding: '5px',
                      fontSize: '1rem'
                    }}
                    value={scheduleTime}
                    onChange={(e) => setScheduleTime(e.target.value)}
                  />

                  <span
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between'
                    }}
                  ></span>
                </div>
                <div
                  className="rounded center-width tUpper"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    color: 'grey',
                    fontSize: '12px'
                  }}
                >
                  <h5>
                    Enter Amount ({token?.identitySymbol || token?.token_symbol}
                    )
                  </h5>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    padding: '5px'
                  }}
                >
                  <input
                    autoFocus
                    type={'number'}
                    style={{
                      outline: 'none',
                      backgroundColor: 'white',
                      width: '95%',
                      padding: '5px',
                      fontSize: '1rem'
                    }}
                    value={swapAmount}
                    onChange={(e) => setSwapAmount(e.target.value)}
                  />

                  <span
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between'
                    }}
                  ></span>
                </div>
              </div>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
            <Paper
              style={{
                textAlign: 'center',
                backgroundColor: '#00071a',
                minWidth: '300px'
              }}
              className="border tShadow"
            >
              {' '}
              <div
                className="rounded center-width tUpper"
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  color: 'grey',
                  fontSize: '13px'
                }}
              >
                <h5>Estimated Total</h5>
                {/* <h5>{(swapAmount * limitPrice) / PecuPrice} PECU</h5> */}
              </div>
              <div
                style={{
                  color: 'white',
                  wordWrap: 'break-word'
                }}
              >
                {' '}
                <Avatar
                  className="rounded"
                  alt="token logo"
                  style={{
                    width: '25px',
                    height: '25px',
                    color: 'rgb(86, 90, 105)',
                    marginRight: '8px'
                  }}
                  src={`https://api.hootdex.net/hootdex/images/pecuCoin.png`}
                />
                <div
                  className="rounded center-width tUpper"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    color: 'grey',
                    fontSize: '13px'
                  }}
                >
                  <h5>Total (PECU)</h5>
                  {/* <h5>{(swapAmount * limitPrice) / PecuPrice} PECU</h5> */}
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    padding: '5px'
                  }}
                >
                  <input
                    autoFocus
                    type={'number'}
                    style={{
                      outline: 'none',
                      backgroundColor: 'white',
                      width: '95%',
                      padding: '5px',
                      fontSize: '1rem'
                    }}
                    disabled
                    value={(swapAmount * limitPrice) / PecuPrice}
                  />
                </div>
                <div
                  className="rounded center-width tUpper"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    color: 'grey',
                    fontSize: '13px'
                  }}
                >
                  <h5>Total (USD)</h5>
                  {/* <h5>{swapAmount * limitPrice} USD</h5> */}
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    padding: '5px'
                  }}
                >
                  <input
                    autoFocus
                    type={'number'}
                    style={{
                      outline: 'none',
                      backgroundColor: 'white',
                      width: '95%',
                      padding: '5px',
                      fontSize: '1rem'
                    }}
                    disabled
                    value={(swapAmount * limitPrice)?.toFixed(5)}
                  />
                </div>
              </div>
            </Paper>
          </Grid>

          <Grid item xs={10}>
            <div
              style={{
                width: '100%',
                margin: '0.5rem 0 0.5rem 0',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                borderRadius: '1rem'
              }}
            >
              <label
                style={{ color: 'white', fontWeight: 'bold', margin: '1rem' }}
              >
                Order Type
              </label>{' '}
              {/*
              <Switch
                title="Partial Order"
                value={partialOrder}
                onChange={() => {
                  setPartialOrder(!partialOrder);
                }}
              /> */}
              <select
                style={{ width: '100%', padding: '8px' }}
                onChange={(e) => {
                  setPartialOrder(e.target.value);
                }}
              >
                <option value={'Partial'}>Partial</option>
                <option value={'All Or None'}>All Or None</option>
              </select>
            </div>
            <div style={{ textAlign: 'center' }}>
              <Button
                onClick={placeOrder}
                className="border"
                variant="contained"
                sx={{
                  color: 'white',
                  backgroundColor: '#01402b',
                  width: '100%'
                }}
              >
                Place Buy Order
              </Button>
            </div>
          </Grid>
        </Grid>
      </Box>
    );
  }
  if (swapType === OrderTypeEnum.SELL_LIMIT_ORDER) {
    return (
      <Box
        className="border"
        sx={{ backdropFilter: 'blur(5px)', p: 3, height: 'max-content' }}
      >
        <span
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-evenly'
          }}
        >
          <select
            style={{
              width: '100%',
              padding: '12px',
              backgroundColor: 'black',
              color: 'orange',
              fontWeight: 'bold',
              border: 'none',
              outline: 'none',
              marginBottom: '1rem'
            }}
            value={swapType}
            onChange={(e) => {
              setSwapType(e.target.value);
            }}
          >
            {orderTypeList.map((e, i) => (
              <option value={e} key={i}>
                {e}
              </option>
            ))}
          </select>
        </span>
        <span
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-evenly'
          }}
        >
          <span
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center'
            }}
          >
            <Avatar
              className="rounded"
              alt="token logo"
              style={{
                width: '16px',
                height: '16px',
                color: 'rgb(86, 90, 105)',
                marginRight: '8px',
                color: 'gray'
              }}
              src={token?.logo || token?.image}
            />{' '}
            <p className="fontS10" style={{ color: 'grey', fontSize: '12px' }}>
              1 {token?.identitySymbol || token?.token_symbol}
            </p>
          </span>
          <p className="fontS10" style={{ color: 'grey', fontSize: '12px' }}>
            {'='}
          </p>
          <span
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center'
            }}
          >
            <p className="fontS15" style={{ color: 'grey', fontSize: '12px' }}>
              {bidPrice?.toFixed(5)?.toLocaleString('en-US')} USD
            </p>
          </span>
        </span>
        <span
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-evenly'
          }}
        >
          <span
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center'
            }}
          >
            <Avatar
              className="rounded"
              alt="token logo"
              style={{
                width: '16px',
                height: '16px',
                color: 'rgb(86, 90, 105)',
                marginRight: '8px',
                color: 'gray'
              }}
              src={`https://api.hootdex.net/hootdex/images/pecuCoin.png`}
            />{' '}
            <p className="fontS10" style={{ color: 'grey', fontSize: '12px' }}>
              1 {'PECU'}
            </p>
          </span>
          <p className="fontS10" style={{ color: 'grey', fontSize: '12px' }}>
            {'='}
          </p>
          <span
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center'
            }}
          >
            <p className="fontS15" style={{ color: 'grey', fontSize: '12px' }}>
              {PecuPrice?.toFixed(5)?.toLocaleString('en-US')} USD
            </p>
          </span>
        </span>
        <br></br>
        <Grid
          container
          spacing={{ xs: 1, md: 2 }}
          style={{ flexDirection: 'column' }}
        >
          <Grid item xs={12} md={4}>
            <Paper
              style={{
                textAlign: 'center',
                backgroundColor: '#00071a',
                minWidth: '300px'
              }}
              className="border tShadow"
            >
              {' '}
              <div
                style={{
                  color: 'white',
                  wordWrap: 'break-word'
                }}
              >
                <Avatar
                  className="rounded"
                  alt="token logo"
                  style={{
                    width: '28px',
                    height: '28px',
                    color: 'rgb(86, 90, 105)',
                    margin: '8px'
                  }}
                  src={token?.logo || token?.image}
                />{' '}
                {/* <div
                  className="rounded center-width tUpper"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    color: 'grey',
                    fontSize: '12px'
                  }}
                >
                  <h5>Price({'USD'})</h5>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    padding: '5px'
                  }}
                >
                  <input
                    autoFocus
                    type={'number'}
                    style={{
                      outline: 'none',
                      backgroundColor: 'white',
                      width: '95%',
                      padding: '5px',
                      fontSize: '1rem'
                    }}
                    disabled
                    value={token?.currentPrice
                      ?.toFixed(5)
                      ?.toLocaleString('en-US')}
                    onChange={(e) => setSwapAmount(e.target.value)}
                  />

                  <span
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between'
                    }}
                  ></span>
                </div> */}
                <div
                  className="rounded center-width tUpper"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    color: 'grey',
                    fontSize: '12px'
                  }}
                >
                  <h5>Limit Price({'USD'})</h5>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    padding: '5px'
                  }}
                >
                  <input
                    autoFocus
                    type={'number'}
                    style={{
                      outline: 'none',
                      backgroundColor: 'white',
                      width: '95%',
                      padding: '5px',
                      fontSize: '1rem'
                    }}
                    value={limitPrice}
                    onChange={(e) => setLimitPrice(e.target.value)}
                  />

                  <span
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between'
                    }}
                  ></span>
                </div>
                <div
                  className="rounded center-width tUpper"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    color: 'grey',
                    fontSize: '12px'
                  }}
                >
                  <h5>
                    Time of Limit Order(
                    {token?.identitySymbol || token?.token_symbol})
                  </h5>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    padding: '5px'
                  }}
                >
                  <input
                    autoFocus
                    type={'datetime-local'}
                    style={{
                      outline: 'none',
                      backgroundColor: 'white',
                      width: '95%',
                      padding: '5px',
                      fontSize: '1rem'
                    }}
                    value={scheduleTime}
                    onChange={(e) => setScheduleTime(e.target.value)}
                  />

                  <span
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between'
                    }}
                  ></span>
                </div>
                <div
                  className="rounded center-width tUpper"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    color: 'grey',
                    fontSize: '12px'
                  }}
                >
                  <h5>
                    Enter Amount ({token?.identitySymbol || token?.token_symbol}
                    )
                  </h5>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    padding: '5px'
                  }}
                >
                  <input
                    autoFocus
                    type={'number'}
                    style={{
                      outline: 'none',
                      backgroundColor: 'white',
                      width: '95%',
                      padding: '5px',
                      fontSize: '1rem'
                    }}
                    value={swapAmount}
                    onChange={(e) => setSwapAmount(e.target.value)}
                  />

                  <span
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between'
                    }}
                  ></span>
                </div>
              </div>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
            <Paper
              style={{
                textAlign: 'center',
                backgroundColor: '#00071a',
                minWidth: '300px'
              }}
              className="border tShadow"
            >
              {' '}
              <div
                className="rounded center-width tUpper"
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  color: 'grey',
                  fontSize: '13px'
                }}
              >
                <h5>Estimated Total</h5>
                {/* <h5>{(swapAmount * limitPrice) / PecuPrice} PECU</h5> */}
              </div>
              <div
                style={{
                  color: 'white',
                  wordWrap: 'break-word'
                }}
              >
                {' '}
                <Avatar
                  className="rounded"
                  alt="token logo"
                  style={{
                    width: '25px',
                    height: '25px',
                    color: 'rgb(86, 90, 105)',
                    marginRight: '8px'
                  }}
                  src={`https://api.hootdex.net/hootdex/images/pecuCoin.png`}
                />
                <div
                  className="rounded center-width tUpper"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    color: 'grey',
                    fontSize: '13px'
                  }}
                >
                  <h5>Total (PECU)</h5>
                  {/* <h5>{(swapAmount * limitPrice) / PecuPrice} PECU</h5> */}
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    padding: '5px'
                  }}
                >
                  <input
                    autoFocus
                    type={'number'}
                    style={{
                      outline: 'none',
                      backgroundColor: 'white',
                      width: '95%',
                      padding: '5px',
                      fontSize: '1rem'
                    }}
                    disabled
                    value={(swapAmount * limitPrice) / PecuPrice}
                  />
                </div>
                <div
                  className="rounded center-width tUpper"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    color: 'grey',
                    fontSize: '13px'
                  }}
                >
                  <h5>Total (USD)</h5>
                  {/* <h5>{swapAmount * limitPrice} USD</h5> */}
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    padding: '5px'
                  }}
                >
                  <input
                    autoFocus
                    type={'number'}
                    style={{
                      outline: 'none',
                      backgroundColor: 'white',
                      width: '95%',
                      padding: '5px',
                      fontSize: '1rem'
                    }}
                    disabled
                    value={(swapAmount * limitPrice)?.toFixed(5)}
                  />
                </div>
              </div>
            </Paper>
          </Grid>

          <Grid item xs={10}>
            <div
              style={{
                width: '100%',
                margin: '0.5rem 0 0.5rem 0',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                borderRadius: '1rem'
              }}
            >
              <label
                style={{ color: 'white', fontWeight: 'bold', margin: '1rem' }}
              >
                Order Type
              </label>{' '}
              {/*
              <Switch
                title="Partial Order"
                value={partialOrder}
                onChange={() => {
                  setPartialOrder(!partialOrder);
                }}
              /> */}
              <select
                style={{ width: '100%', padding: '8px' }}
                onChange={(e) => {
                  setPartialOrder(e.target.value);
                }}
              >
                <option value={'Partial fill'}>PARTIAL FILL</option>
                <option value={'All Or None'}>ALL OR NONE</option>
              </select>
            </div>
            <div style={{ textAlign: 'center' }}>
              <Button
                onClick={placeOrder}
                className="border"
                variant="contained"
                sx={{
                  color: 'white',
                  backgroundColor: '#01402b',
                  width: '100%'
                }}
              >
                Place Sell Order
              </Button>
            </div>
          </Grid>
        </Grid>
      </Box>
    );
  }
  if (swapType === OrderTypeEnum.TOKEN_SWAP) {
    return (
      <Box
        className="border"
        sx={{ backdropFilter: 'blur(5px)', p: 3, height: 'max-content' }}
      >
        <span
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-evenly'
          }}
        >
          <select
            style={{
              width: '100%',
              padding: '12px',
              backgroundColor: 'black',
              color: 'orange',
              fontWeight: 'bold',
              border: 'none',
              outline: 'none',
              marginBottom: '1rem'
            }}
            value={swapType}
            onChange={(e) => {
              setSwapType(e.target.value);
            }}
          >
            {orderTypeList.map((e, i) => (
              <option value={e} key={i}>
                {e}
              </option>
            ))}
          </select>
        </span>
        <span
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-evenly'
          }}
        >
          <span
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center'
            }}
          >
            <Avatar
              className="rounded"
              alt="token logo"
              style={{
                width: '16px',
                height: '16px',
                color: 'rgb(86, 90, 105)',
                marginRight: '8px',
                color: 'gray'
              }}
              src={token?.logo || token?.image}
            />{' '}
            <p className="fontS10" style={{ color: 'grey', fontSize: '12px' }}>
              1 {token?.identitySymbol || token?.token_symbol}
            </p>
          </span>
          <p className="fontS10" style={{ color: 'grey', fontSize: '12px' }}>
            {'='}
          </p>
          <span
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center'
            }}
          >
            <p className="fontS15" style={{ color: 'grey', fontSize: '12px' }}>
              {token?.currentPrice?.toFixed(5)?.toLocaleString('en-US')}
              {token?.token_price?.toFixed(5)?.toLocaleString('en-US')} USD
            </p>
          </span>
        </span>
        <span
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-evenly'
          }}
        >
          <span
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center'
            }}
          >
            <Avatar
              className="rounded"
              alt="token logo"
              style={{
                width: '16px',
                height: '16px',
                color: 'rgb(86, 90, 105)',
                marginRight: '8px',
                color: 'gray'
              }}
              src={`https://api.hootdex.net/hootdex/images/pecuCoin.png`}
            />{' '}
            <p className="fontS10" style={{ color: 'grey', fontSize: '12px' }}>
              1 {'PECU'}
            </p>
          </span>
          <p className="fontS10" style={{ color: 'grey', fontSize: '12px' }}>
            {'='}
          </p>
          <span
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center'
            }}
          >
            <p className="fontS15" style={{ color: 'grey', fontSize: '12px' }}>
              {PecuPrice?.toFixed(5)?.toLocaleString('en-US')} USD
            </p>
          </span>
        </span>
        <br></br>
        <Grid
          container
          spacing={{ xs: 1, md: 2 }}
          style={{ flexDirection: 'column' }}
        >
          <Grid item xs={12} md={4}>
            <Paper
              style={{
                textAlign: 'center',
                backgroundColor: '#00071a',
                minWidth: '300px'
              }}
              className="border tShadow"
            >
              {' '}
              <div
                style={{
                  color: 'white',
                  wordWrap: 'break-word'
                }}
              >
                <Avatar
                  className="rounded"
                  alt="token logo"
                  style={{
                    width: '28px',
                    height: '28px',
                    color: 'rgb(86, 90, 105)',
                    margin: '8px'
                  }}
                  src={token?.logo || token?.image}
                />{' '}
                <div
                  className="rounded center-width tUpper"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    color: 'grey',
                    fontSize: '12px'
                  }}
                >
                  <h5>
                    Enter Amount ({token?.identitySymbol || token?.token_symbol}
                    )
                  </h5>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    padding: '5px'
                  }}
                >
                  <input
                    autoFocus
                    type={'number'}
                    style={{
                      outline: 'none',
                      backgroundColor: 'white',
                      width: '95%',
                      padding: '5px',
                      fontSize: '1rem'
                    }}
                    value={swapAmount}
                    onChange={(e) => setSwapAmount(e.target.value)}
                  />

                  <span
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between'
                    }}
                  ></span>
                </div>
              </div>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
            <Paper
              style={{
                textAlign: 'center',
                backgroundColor: '#00071a',
                minWidth: '300px'
              }}
              className="border tShadow"
            >
              {' '}
              <div
                className="rounded center-width tUpper"
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  color: 'grey',
                  fontSize: '13px'
                }}
              >
                <h5>Estimated Total</h5>
                {/* <h5>{(swapAmount * limitPrice) / PecuPrice} PECU</h5> */}
              </div>
              <div
                style={{
                  color: 'white',
                  wordWrap: 'break-word'
                }}
              >
                {' '}
                <Avatar
                  className="rounded"
                  alt="token logo"
                  style={{
                    width: '25px',
                    height: '25px',
                    color: 'rgb(86, 90, 105)',
                    marginRight: '8px'
                  }}
                  src={`https://api.hootdex.net/hootdex/images/pecuCoin.png`}
                />
                <div
                  className="rounded center-width tUpper"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    color: 'grey',
                    fontSize: '13px'
                  }}
                >
                  <h5>Total (PECU)</h5>
                  {/* <h5>{(swapAmount * limitPrice) / PecuPrice} PECU</h5> */}
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    padding: '5px'
                  }}
                >
                  <input
                    autoFocus
                    type={'number'}
                    style={{
                      outline: 'none',
                      backgroundColor: 'white',
                      width: '95%',
                      padding: '5px',
                      fontSize: '1rem'
                    }}
                    disabled
                    value={
                      token?.currentPrice
                        ? (swapAmount * token?.currentPrice) / PecuPrice
                        : (swapAmount * token?.token_price) / PecuPrice
                    }
                  />
                </div>
                <div
                  className="rounded center-width tUpper"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    color: 'grey',
                    fontSize: '13px'
                  }}
                >
                  <h5>Total (USD)</h5>
                  {/* <h5>{swapAmount * limitPrice} USD</h5> */}
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    padding: '5px'
                  }}
                >
                  <input
                    autoFocus
                    type={'number'}
                    style={{
                      outline: 'none',
                      backgroundColor: 'white',
                      width: '95%',
                      padding: '5px',
                      fontSize: '1rem'
                    }}
                    disabled
                    value={(token?.currentPrice
                      ? swapAmount * token?.currentPrice
                      : swapAmount * token?.token_price
                    )?.toFixed(5)}
                  />
                </div>
                <div
                  className="rounded center-width tUpper"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',

                    fontSize: '13px',
                    color: 'white'
                  }}
                >
                  <h5>Swap To</h5>
                  {/* <h5>{(swapAmount * limitPrice) / PecuPrice} PECU</h5> */}
                </div>
                <span
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-evenly'
                  }}
                >
                  {' '}
                  <select
                    style={{
                      width: '100%',
                      padding: '12px',
                      backgroundColor: 'black',
                      color: 'white',
                      fontWeight: 'bold',

                      outline: 'none',
                      marginBottom: '1rem',

                      border: '1px solid white'
                    }}
                    onChange={(e) => {
                      setSwapSymbol(e.target.value);
                    }}
                  >
                    {projectTokens.map((e, i) => (
                      <option value={e?.token_symbol} key={i}>
                        {e?.token_symbol} ({e?.token_price} USD)
                      </option>
                    ))}
                    {holdingTokens.map((e, i) => (
                      <option value={e?.token_symbol} key={i}>
                        {e?.token_symbol} ({e?.token_price}USD)
                      </option>
                    ))}
                  </select>
                </span>
              </div>
            </Paper>
          </Grid>

          <Grid item xs={12}>
            <div style={{ textAlign: 'center' }}>
              <Button
                onClick={() => swapTokens(token, swapSymbol, swapAmount)}
                className="border"
                variant="contained"
                sx={{
                  color: 'white',
                  backgroundColor: '#01402b',
                  width: '100%'
                }}
              >
                Swap
              </Button>
            </div>
          </Grid>
        </Grid>
      </Box>
    );
  }
};

function Derivatives() {
  const params = useParams();
  const {
    PecuPrice,
    projectTokens,
    wrapTokens,
    holdingTokens,
    liquidityPools,
    derivatives,
    synthetics,
    UserInfo,
    WalletInfo,
    syntheticsList,
    derivativesList,
    projectList,
    holdingList,
    tokenList
  } = useSelector((state) => state);
  const [tokenSymbol, setTokenSymbol] = useState(params.symbol);
  const [value, setValue] = useState();
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState();
  const [showWallet, setShowWallet] = useState(true);
  const [bid, setBid] = useState([]);
  const [ask, setAsk] = useState([]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [derivativesTokens, setDerivativesTokens] = useState([]);

  const [token, setToken] = useState(null);

  const [exchangeTokenPrice, setExchangeTokenPrice] = useState();

  const getTokenPercentage = (token) => {
    return token?.type === 'STNTHETICS'
      ? ((token?.currentPrice - token?.price24hour) / token?.price24hour) * 100
      : exchangeTokenPrice?.change_24h;
  };
  useEffect(() => {
    if (derivatives && derivatives.tokens) {
      setDerivativesTokens(derivatives.tokens);
    }
  }, [derivatives]);

  const fetchExchangeTokenPrice = async (symbol) => {
    const response = await axios(
      `${url}/crypto/exchangePriceData?symbol=${symbol}`
    );
    setExchangeTokenPrice(response.data[0]);
  };

  // useEffect(() => {
  //   setExchangeTokenPrice(null);
  //   let symbolToFetch = token?.symbol || token?.token_symbol;
  //   fetchExchangeTokenPrice(symbolToFetch);
  // }, [token]);

  // useEffect(() => {
  //   if (!token) {
  //     const defaultToken = tokenSymbol ? tokenSymbol : 'hBTC';
  //     const selcted = [
  //       ...synthetics,
  //       ...derivativesTokens,
  //       ...projectTokens,
  //       ...holdingTokens
  //     ].find(
  //       (e) =>
  //         e.token_symbol === defaultToken || e.identitySymbol === defaultToken
  //     );

  //     setToken(selcted);
  //   }
  // }, [
  //   derivativesTokens,
  //   holdingTokens,
  //   projectTokens,
  //   synthetics,
  //   token,
  //   tokenSymbol
  // ]);
  // useEffect(() => {
  //   if (token && token.symbol && token.identitySymbol) {
  //     const selcted = [...synthetics, ...derivativesTokens].find(
  //       (e) => e.identitySymbol === token.identitySymbol
  //     );
  //     if (token.currentPrice !== selcted.currentPrice) {
  //       let localToken = { ...token };
  //       localToken.currentPrice = selcted.currentPrice;
  //       setToken(localToken);
  //     }
  //   }
  // }, [synthetics, derivatives, derivativesTokens]);

  const handleSelectionChange = (event) => {
    // const selcted = [...synthetics, ...derivativesTokens].find(
    //   (e) => e.identitySymbol === event.target.value
    // );
    // const selctedPT = [...projectTokens, ...holdingTokens].find(
    //   (e) => e.token_symbol === event.target.value
    // );
    // if (selctedPT) {
    //   setToken(selctedPT);
    //   // eslint-disable-next-line no-restricted-globals
    //   setTokenSymbol(event.target.value);
    // } else {
    //   setToken(selcted);
    //   setTokenSymbol(event.target.value);
    // }

    setTokenSymbol(event.target.value);
  };
  const fetchToken = (symbol) => {
    if (syntheticsList.includes(symbol)) {
      axios.get(`${url}/crypto/get_synthetics?symbol=${symbol}`).then((res) => {
        if (res.data && res.data.stock) {
          setToken(res.data.stock[0]);
          setLoading(false);
        }
      });
    } else if (derivativesList.includes(symbol)) {
      axios
        .get(`${url}/crypto/get_derivatives?symbol=${symbol}`)
        .then((res) => {
          if (res.data && res.data.stock) {
            setToken(res.data.stock[0]);
            setLoading(false);
          }
        });
    } else if (holdingList.includes(symbol)) {
      axios
        .get(`${url}/hootdex/get_my_tokens_project?symbol=${symbol}`)
        .then((res) => {
          if (res.data && res.data.tokens) {
            setToken(res.data.tokens[0]);
            setLoading(false);
          }
        });
    } else if (projectList.includes(symbol)) {
      axios
        .get(`${url}/wallet/get_tokens_project_by_symbol?symbol=${symbol}`)
        .then((res) => {
          setToken(res.data.token[0]);

          // setFirstChart(res.data.chart);
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchToken(tokenSymbol);
    fetchExchangeTokenPrice(tokenSymbol);
  }, [tokenSymbol]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      fetchToken(tokenSymbol);
    }, 2000);
    // Cleanup function to clear the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
    };
  }, [tokenSymbol]);
  return (
    <div
      onMouseEnter={() => {
        setShowModal();
        setValue();
      }}
    >
      {loading && (
        <Backdrop sx={{ color: 'green', zIndex: 9999 }} open={true}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <Grid className="Full_Page">
        <Grid className="Page_Grid">
          <Grid className="Grid_Column">
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                backgroundColor: 'black'
              }}
            >
              <select
                style={{
                  padding: '12px',
                  backgroundColor: 'black',
                  color: 'orange',
                  fontWeight: 'bold',
                  border: 'none',
                  outline: 'none',
                  maxWidth: '350px'
                }}
                onChange={handleSelectionChange}
                value={tokenSymbol}
              >
                {tokenList['synthetics']?.map((each, index) => (
                  <option key={index} value={each.symbol}>
                    <small
                      style={{
                        fontSize: '13px',
                        color: 'palegreen'
                      }}
                    >
                      {each.symbol} ({each.name})
                    </small>
                  </option>
                ))}
                {tokenList['crypto_pairs']?.map((each, index) => (
                  <option key={index} value={each.symbol}>
                    <small
                      style={{
                        fontSize: '13px',
                        color: 'palegreen'
                      }}
                    >
                      {each.symbol} ({each.name})
                    </small>
                  </option>
                ))}
                {tokenList['project_tokens']?.map((each, index) => (
                  <option key={index} value={each.symbol}>
                    <small
                      style={{
                        fontSize: '13px',
                        color: 'palegreen'
                      }}
                    >
                      {each.symbol} ({each.name})
                    </small>
                  </option>
                ))}
                {tokenList['holding_tokens']?.map((each, index) => (
                  <option key={index} value={each.symbol}>
                    <small
                      style={{
                        fontSize: '13px',
                        color: 'palegreen'
                      }}
                    >
                      {each.symbol} ({each.name})
                    </small>
                  </option>
                ))}
              </select>
              <Typography
                color={'rgb(132, 142, 156)'}
                style={{
                  fontSize: '12px',
                  lineHeight: '16px',
                  paddingLeft: '8px',
                  color: 'orange'
                }}
              >
                {'Global price'}
                <br></br>
                {token?.currentPrice?.toFixed(5)?.toLocaleString('en-US') ||
                  token?.token_price?.toFixed(5)?.toLocaleString('en-US')}{' '}
                {'      '}{' '}
              </Typography>
              <Typography
                color={'rgb(132, 142, 156)'}
                style={{
                  fontSize: '12px',
                  lineHeight: '16px',
                  paddingLeft: '8px',
                  color: 'orange'
                }}
                className={
                  token?.currentPrice - token?.price24hour >= 0
                    ? `twhite green`
                    : `twhite red`
                }
                align="left"
              >
                {'24h Change'}
                <br></br>
                {/* {token?.price24hour?.toFixed(5)?.toLocaleString('en-US')} */}
                {token?.currentPrice - token?.price24hour > 0 ? (
                  <ArrowUpward sx={{ fontSize: '13px' }} />
                ) : token?.currentPrice - token?.price24hour < 0 ? (
                  <ArrowDownward sx={{ fontSize: '13px' }} />
                ) : (
                  <ArrowUpward sx={{ fontSize: '13px' }} />
                )}
                {getTokenPercentage(token)?.toFixed(5)} %
              </Typography>
              <Typography
                color={'rgb(132, 142, 156)'}
                style={{
                  fontSize: '12px',
                  lineHeight: '16px',
                  paddingLeft: '8px',
                  color: 'orange'
                }}
              >
                {'24h High'}
                <br></br>
                {exchangeTokenPrice?.high_24h
                  ?.toFixed(5)
                  ?.toLocaleString('en-US')}
              </Typography>
              <Typography
                color={'rgb(132, 142, 156)'}
                style={{
                  fontSize: '12px',
                  lineHeight: '16px',
                  paddingLeft: '8px',
                  color: 'orange'
                }}
              >
                {'24h Low'}
                <br></br>
                {exchangeTokenPrice?.low_24h
                  ?.toFixed(5)
                  ?.toLocaleString('en-US')}
              </Typography>
              <Typography
                color={'rgb(132, 142, 156)'}
                style={{
                  fontSize: '12px',
                  lineHeight: '16px',
                  paddingLeft: '8px',
                  color: 'orange'
                }}
              >
                {exchangeTokenPrice?.volume_24h
                  ?.toFixed(5)
                  ?.toLocaleString('en-US')}
                <br></br>
              </Typography>

              <Typography
                color={'rgb(132, 142, 156)'}
                style={{
                  fontSize: '12px',
                  lineHeight: '16px',
                  paddingLeft: '8px',
                  marginRight: '10px',
                  color: WalletInfo ? 'green' : 'red',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                {' '}
                {WalletInfo ? 'Wallet Connected' : 'Wallet Disconnected'}{' '}
                <SignalCellularAltIcon />
              </Typography>
            </div>
          </Grid>
          <Grid className="Left_panel">
            <LeftPanel
              tokenData={token}
              tokenSymbol={tokenSymbol}
              lastAsk={(ask) => setAsk(ask)}
              lastBid={(bid) => setBid(bid)}
            />
          </Grid>
          <Grid className="Right_panel">
            <Grid
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                backgroundColor: 'black'
              }}
            >
              {' '}
              <Grid className="Top_Table table_wrapper">
                <Table token={token} />
              </Grid>
              <Grid className="Top_Table table_wrapper">
                <SwapSystem
                  token={token}
                  PecuPrice={PecuPrice}
                  projectTokens={projectTokens}
                  holdingTokens={holdingTokens}
                  bid={bid}
                  ask={ask}
                />
              </Grid>
            </Grid>

            <Grid>
              <Grid className="mainTab_Accordion">
                <Box
                  sx={{
                    borderBottom: 1,
                    borderColor: 'divider',
                    width: '100%',
                    backgroundColor: 'black',
                    display: 'flex',
                    alignItems: 'baseline',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    border: '1px solid rgb(43, 49, 57)',
                    marginTop: '2px'
                  }}
                >
                  {' '}
                  <Avatar
                    className="rounded"
                    alt="token logo"
                    style={{
                      width: '30px',
                      height: '30px',
                      color: 'rgb(86, 90, 105)'
                    }}
                    src={`https://api.hootdex.net/hootdex/images/pecuCoin.png`}
                  />
                  <Tab
                    label="PECU WALLET"
                    className="headerTab"
                    style={{ color: 'whitesmoke', fontWeight: 'bold' }}
                    onClick={() => {
                      setShowWallet(true);
                    }}
                  />
                  <Tab
                    label="MY SWAPS"
                    className="headerTab"
                    style={{ color: 'whitesmoke', fontWeight: 'bold' }}
                    onClick={() => {
                      setShowWallet(false);
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
            <Grid>{showWallet ? <TradeTable /> : <MyTradeTable />}</Grid>
            <Grid className="Bottom_Table table_wrapper2"></Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default React.memo(Derivatives);
