import React from 'react';
import {
  Avatar,
  LinearProgress,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import { api as axios } from '../../api';
import { useEffect, useState } from 'react';
import url from '../../serverUrl';
import symbols from '../../symbolsMapper';
function getFormattedTime(timestampString) {
  const timestamp = new Date(timestampString);

  return timestamp;
}
const Transactions = () => {
  const [loading, setLoading] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [currentValue, setCurrentValue] = useState(0);
  const [marketTrade, setMarketTrade] = useState([]);
  const get_current_index_coin = () => {
    axios
      .get(`${url}/wallet/get_current_index_coin`)
      .then((res) => {
        setCurrentValue(res.data[0].value);
      })
      .catch((err) => {
        // console.log(err);
      });
  };
  const fetchToken = (target) => {
    if (target === 'all') {
      setLoading(true);
      axios
        .get(`${url}/hootdex/available-tokens-transactions`)
        .then((res) => {
          setTransactions(res.data);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    } else {
      setLoading(true);
      setTransactions(transactions.filter((each) => each.tokenName === target));
      setLoading(false);
    }
  };
  const fetchTrade = () => {
    axios
      .get(`${url}/crypto/marketTrade`)
      .then((res) => {
        setTransactions(res.data);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    // fetchToken("all");
    fetchTrade();
    //get_current_index_coin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <TableContainer
        sx={{
          backgroundColor: '#1a1b1f',
          mt: 5,
          borderRadius: '1rem',
          maxHeight: '300px',
          paddingBottom: '1rem',
          overflowY: 'auto'
        }}
        component={Paper}
      >
        <p
          style={{
            color: 'rgb(195, 197, 203)',
            fontSize: '15px',
            fontWeight: '600',
            textAlign: 'left',
            backgroundColor: '#21242b',
            padding: '1rem'
          }}
        >
          All Transactions
        </p>
        {loading && <LinearProgress color="inherit" />}
        {transactions.length ? (
          <Table
            sx={{
              [`& .${tableCellClasses.root}`]: {
                borderBottom: ' 1px solid #1e2128'
              }
            }}
          >
            <TableHead className="">
              <TableRow className="">
                {/* {poolTableAttributes.map((e, index) => ( */}
                <TableCell className="twhite" component="th" scope="row">
                  Symbol
                </TableCell>
                <TableCell className="twhite"> Amount</TableCell>
                <TableCell className="twhite" align="left">
                  Price
                </TableCell>
                <TableCell className="twhite" align="left">
                  Value
                </TableCell>

                <TableCell className="twhite" align="left">
                  Time
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {transactions &&
                transactions
                  .filter(
                    (e) =>
                      !e.symbol.includes('/PECU') &&
                      !e.symbol.includes('PECU/') &&
                      e.price !== 0
                  )
                  .map((each, index) => (
                    <TableRow key={each.id}>
                      <a
                        href={`https://app.hootdex.net/${
                          symbols[each.symbol] ?? each.symbol
                        }`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <TableCell
                          className="twhite"
                          component="th"
                          scope="row"
                        >
                          {' '}
                          {symbols[each.symbol] ?? each.symbol}
                        </TableCell>{' '}
                      </a>

                      <TableCell className="twhite green" align="left">
                        {each.amount}
                      </TableCell>
                      <TableCell className="twhite yellow" align="left">
                        {each.price}
                      </TableCell>
                      <TableCell className="twhite yellow" align="left">
                        {each.price * each.amount}
                      </TableCell>

                      <TableCell className="twhite pink" align="left">
                        {getFormattedTime(each.timestamp).toUTCString()}
                      </TableCell>
                    </TableRow>
                  ))}

              {/* <TablePagination
                sx={{ color: "white" }}
                rowsPerPageOptions={[10, 50]}
                onChange={(e) => setRows(e)}
              /> */}
            </TableBody>
          </Table>
        ) : (
          <Skeleton
            sx={{ bgcolor: '#21242b', mt: 1 }}
            variant="rectangular"
            margin={'1rem'}
            height={100}
          />
        )}
      </TableContainer>
    </>
  );
};

export default Transactions;
