import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import url from '../../../serverUrl';
import { api as axios } from '../../../api';
import { useState } from 'react';
import { useEffect } from 'react';
import symbols from '../../../symbolsMapper';
import { useSelector } from 'react-redux';

export default function TradeTable() {
  const { WalletInfo } = useSelector((state) => state);
  const [totalCoins, setTotalCoins] = useState('0.00000000');
  const [totalValue, setTotalValue] = useState('0.00000000');
  const [holdingTokens, setHoldingToken] = useState([]);
  const [user, setUser] = useState();
  useEffect(() => {
    if (WalletInfo && WalletInfo !== 'null' && WalletInfo !== null) {
      setUser(WalletInfo);
    }
  }, []);
  const getMyCoins = (id) => {
    if (id) {
      axios.get(`${url}/walletBeta/getMycoins?user_id=${id}`).then((res) => {
        const { total_coins, value } = res.data;
        setTotalCoins(total_coins);
        setTotalValue(value);
      });
    }
  };
  const removeDuplicatedToken = (allData) => {
    for (let i = 0; i < allData.length; i++) {
      for (let j = i + 1; j < allData.length; j++) {
        if (allData[i].symbol == allData[j].symbol) {
          allData[i].assetAmount =
            allData[j].assetAmount + allData[i].assetAmount;

          allData = allData.filter((e) => e !== allData[j]);
        }
      }
    }

    for (let i = 0; i < allData.length; i++) {
      for (let j = i + 1; j < allData.length; j++) {
        if (allData[i].symbol == allData[j].symbol) {
          return removeDuplicatedToken(allData);
        }
      }
    }

    return allData;
  };
  const getMyTokens = (id) => {
    if (id) {
      axios
        .get(`${url}/wallet/get_my_assetWallet_data?userId=${id}`)
        .then(async (res) => {
          if (res.data) {
            let storedData = await removeDuplicatedToken(res.data.assets);
            setHoldingToken(storedData);
          }
        })
        .catch((err) => console.log(err));
    }
  };
  const [wrapTokens, setWrapTokens] = useState([]);
  const getMyTokensL = (email) => {
    if (email) {
      axios
        .get(`${url}/wallet/get_my_tokens?email=${email}`)
        .then((res) => {
          if (res.data.status) {
            let fixedData = JSON.parse(JSON.stringify(res.data.tokens));
            setWrapTokens(fixedData);
          }
        })
        .catch((err) => console.log(err));
    }
  };
  const [holdingTokenCompany, setHoldingTokenCompany] = useState([]);
  const getMyHoldingTokens = (email) => {
    if (email) {
      axios
        .get(`${url}/wallet/get_my_tokens_Holding_email?email=${email}`)
        .then((res) => {
          if (res.data.status) {
            let fixedData = res.data.token;
            if (fixedData && fixedData?.length > 0) {
              setHoldingTokenCompany(fixedData);
            }
          }
        })
        .catch((err) => console.log(err));
    }
  };

  useEffect(() => {
    if (user && user.uid) {
      const { uid, email } = user || {};
      getMyCoins(uid);
      getMyTokens(uid);
      getMyTokensL(email);
      getMyHoldingTokens(email);
    }
  }, [user]);
  return (
    <TableContainer
      component={Paper}
      style={{
        backgroundColor: 'rgb(43, 49, 57)',
        maxHeight: '460px',

        overflowY: 'auto'
      }}
    >
      <Table stickyHeader size="small">
        <TableBody className="tbody-table">
          <TableRow
            sx={{
              border: 0,
              backgroundColor: 'black',
              color: 'white'
            }}
            colSpan={8}
          >
            <TableCell>Asset</TableCell>
            <TableCell>Amount</TableCell>
          </TableRow>
          <TableRow
            sx={{
              border: 0,
              backgroundColor: 'rgb(43, 49, 57)',
              color: 'white'
            }}
            colSpan={8}
          >
            <TableCell
              style={{
                color: 'orange',
                fontWeight: 'bold'
              }}
            >
              PECU
            </TableCell>
            <TableCell>{totalCoins}</TableCell>
            {/* <TableCell>{totalValue}</TableCell> */}
          </TableRow>
          {holdingTokens?.map((e) => (
            <TableRow
              sx={{
                border: 0,
                backgroundColor: 'rgb(43, 49, 57)',
                color: 'white'
              }}
              colSpan={8}
            >
              {' '}
              <TableCell
                style={{
                  color: 'orange',
                  fontWeight: 'bold'
                }}
              >
                {symbols[e.symbol] ?? e.symbol}
              </TableCell>
              <TableCell>{e.assetAmount}</TableCell>
            </TableRow>
          ))}
          {holdingTokenCompany?.map((e) => (
            <TableRow
              sx={{
                border: 0,
                backgroundColor: 'rgb(43, 49, 57)',
                color: 'white'
              }}
              colSpan={8}
            >
              {' '}
              <TableCell
                style={{
                  color: 'orange',
                  fontWeight: 'bold'
                }}
              >
                {e?.token_symbol}
              </TableCell>
              <TableCell>{e.amount_issued}</TableCell>
            </TableRow>
          ))}
          {wrapTokens?.map((e) => (
            <TableRow
              sx={{
                border: 0,
                backgroundColor: 'rgb(43, 49, 57)',
                color: 'white'
              }}
              colSpan={8}
            >
              {' '}
              <TableCell
                style={{
                  color: 'orange',
                  fontWeight: 'bold'
                }}
              >
                {e?.symbol}
              </TableCell>
              <TableCell>{e.assetAmount}</TableCell>
            </TableRow>
          ))}

          <TableRow
            sx={{
              border: 0,
              backgroundColor: 'rgb(43, 49, 57)',
              color: 'white'
            }}
            colSpan={8}
          >
            <TableCell>MVault </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
