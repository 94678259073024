import React from 'react';
import './style.css';
export default function Footer() {
  return (
    <div className="footer">
      {/* <div>
        {' '}
        <span className="lightGreen bold-600">MegaHoot Ecosystem | </span>
        <span className="lightGreen bold-600">
          Digital Asset Derivatives |{' '}
        </span>
        <span className="lightGreen bold-600">MVault | </span>
        <span>FAQ | </span>
        <span>Blog </span>
      </div> */}
      <p className="green bold-600">
        BUILT ON A CARBON NEUTRAL BLOCKCHAIN NETWORK 🌱
      </p>
      <div>
        <span>© MegaHoot, LLC. All rights reserved | </span>
        <span>media@hootdex.com | </span>
        <a
          className="lightGreen"
          href="https://www.hootdex.com/privacy/"
          target={'_blank'}
          rel="noreferrer"
        >
          <span>PrivacyPolicy | </span>
        </a>
        <a
          className="lightGreen"
          href="https://www.hootdex.com/risk-disclosure/"
          target={'_blank'}
          rel="noreferrer"
        >
          {' '}
          <span className="lightGreen">Risk Disclosure | </span>
        </a>
        <a
          className="lightGreen"
          href="https://www.hootdex.com/legal/"
          target={'_blank'}
          rel="noreferrer"
        >
          <span className="lightGreen">Legal Disclaimer | </span>
        </a>
        <span>AML/KYC </span>
      </div>
      {/* <p>Top</p> */}
    </div>
  );
}
