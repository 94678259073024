import React, { useEffect, useState } from 'react';

import {
  Box,
  Dialog,
  Alert,
  Button,
  Collapse,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  LinearProgress
} from '@mui/material';
import { api as axios } from '../../api';
import CloseIcon from '@mui/icons-material/Close';
import url from '../../serverUrl';
import { ethers } from 'ethers';
import { useSelector } from 'react-redux';
const AddPecu = ({
  fetchWallet,
  wallet,
  setOpen,
  open,
  setWalletAddress,
  walletAddress
}) => {
  const { PecuPrice } = useSelector((state) => state);
  const [loading, setLoading] = useState(false);
  const [showForm, setShowForm] = useState(true);
  const [amountPecu, setAmountPecu] = useState(1);
  const [amountUsd, setAmountUsd] = useState(1);
  const [userBalance, setUserBalance] = useState(null);
  const [privateKey, setPrivateKey] = useState(null);
  const [web3, setWeb3] = useState(null);
  const [account, setAccount] = useState('');
  const supportedTokens = [
    'ETH - Ethereum',
    'USDT - Tether',
    'USDC - USD Coin',
    'WBTC - Wrapped Bitcoin',
    'DAI - DAI',
    'TUSD - TrueUSD'
  ];
  const requestAccount = async () => {
    if (window && window.ethereum) {
      window.ethereum
        .request({
          method: 'eth_requestAccounts'
        })
        .then((result) => {
          if (result && result.length > 0) {
            // alert(JSON.stringify(`MetaMask is connected`));
            accountChangeHandler(result[0]);
          }
        })
        .catch((err) => console.log(JSON.stringify(err)));
    } else {
      alert('Install MetaMask');
    }
  };
  const accountChangeHandler = (newAccount) => {
    // setDefaultAccount(newAccount);
    // getUserBalance(newAccount);
    // getPrivateKey(newAccount);
    setAccount(newAccount);
    // console.log(newAccount, 'newAccount');
  };

  useEffect(() => {
    requestAccount();
  }, []);

  const [alert, setAlert] = useState({
    msg: '',
    type: '',
    loading: false
  });
  const [email, setEmail] = useState('');
  const handleClose = () => {
    setOpen(false);
    setShowForm(false);
  };

  const handleSubmit = () => {
    if (email) {
      setLoading(true);
      axios
        .post(`${url}/hootdex/connect-wallet`, {
          private_key: email
        })
        .then((res) => {
          setLoading(false);
          if (res.data) {
            localStorage.setItem(
              'hootdex_secretcookie_wallet',
              JSON.stringify(res.data)
            );
            fetchWallet();
            setAlert({
              msg: 'Wallet Connected!',
              type: 'success',
              show: true
            });
            setTimeout(() => {
              handleClose();
              setAlert({
                msg: 'Wallet Connected!',
                type: 'success',
                show: false
              });
            }, 1000);
          } else {
            setTimeout(() => {
              setAlert({
                msg: 'No account found with this key!',
                type: 'error',
                show: false
              });
            }, 3000);
          }
        })
        .catch((err) => {
          setLoading(false);
          setAlert({
            msg: 'No account found with this key!',
            type: 'error',
            show: true
          });
          setTimeout(() => {
            setAlert({
              msg: 'No account found with this key!',
              type: 'error',
              show: false
            });
          }, 3000);
        });
    } else {
      setAlert({
        msg: 'Enter All Fields',
        type: 'error',
        show: true
      });
      setTimeout(() => {
        setAlert({
          msg: 'Enter All Fields',
          type: 'error',
          show: false
        });
      }, 3000);
    }
  };
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            backgroundColor: 'transparent',
            boxShadow: 'none'
          }
        }}
        sx={{ zIndex: 2002 }}
        className="border"
      >
        <Box
          sx={{
            width: 400,
            p: 2,
            borderRadius: '1rem',
            backgroundColor: '#040b1e'
          }}
        >
          <div
            className="twhite tcenter fontS22"
            id="alert-dialog-title"
            style={{
              display: 'flex',
              justifyContent: 'center',
              margin: '2px'
            }}
          >
            PecuNovus
            <img
              style={{ width: '30px', marginLeft: '1rem' }}
              src="https://api.hootdex.net/hootdex/images/pecuCoin.png"
              alt=""
            />
          </div>
          <Divider sx={{ backgroundColor: '#091e17', height: '2px' }} />
          {loading && <LinearProgress />}
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Collapse in={alert.show} sx={{ maxWidth: 400, position: 'fixed' }}>
              <Alert
                variant="outlined"
                severity={alert.type}
                sx={{
                  mb: 2,
                  backgroundColor: 'white',
                  fontSize: '18px'
                }}
              >
                {alert.msg}
              </Alert>
            </Collapse>
          </div>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <p style={{ color: 'white', marginLeft: '15px' }}>
                Amount (Pecu)
              </p>
              <input
                className="border inputfWW"
                type="text"
                name="amountPecu"
                value={amountPecu}
                onChange={(e) => setAmountPecu(e.target.value)}
              />
            </DialogContentText>
            <DialogContentText id="alert-dialog-description" sx={{ mt: 1 }}>
              <p style={{ color: 'white', marginLeft: '15px' }}>Amount (USD)</p>
              <input
                onChange={(e) => setAmountUsd(parseFloat(e.target.value))}
                disabled
                className="border inputfWW"
                type="text"
                name="amountUsd"
                value={amountPecu * PecuPrice}
                required
              />
            </DialogContentText>
            <DialogContentText id="alert-dialog-description" sx={{ mt: 1 }}>
              <p style={{ color: 'white', marginLeft: '15px' }}>Swap Source</p>
              <select
                required={true}
                className="border inputfWW"
                style={{ width: '95%', padding: '6px', fontSize: '15px' }}
              >
                <option>MetaMask</option>
              </select>
            </DialogContentText>
            <DialogContentText id="alert-dialog-description" sx={{ mt: 1 }}>
              <p style={{ color: 'white', marginLeft: '15px' }}>
                Supported Tokens
              </p>
              <select
                required={true}
                className="border inputfWW"
                style={{ width: '95%', padding: '6px', fontSize: '15px' }}
              >
                {supportedTokens.map((e, i) => (
                  <option key={i}>{e}</option>
                ))}
              </select>
            </DialogContentText>
            <DialogContentText
              id="alert-dialog-description"
              sx={{
                mt: '1.5rem',
                backgroundColor: 'whitesmoke',
                width: '90%',
                wordBreak: 'break-word',
                borderRadius: '8px',
                padding: '8px'
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                {' '}
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/3/36/MetaMask_Fox.svg"
                  style={{ width: '50px' }}
                  alt="MetaMask"
                />
                <h4>MetaMask Integration</h4>
              </div>
              {account ? (
                <p>Connected Account: {account}</p>
              ) : (
                <p>Please connect to MetaMask</p>
              )}
              {/* Add transaction functionality here */}
            </DialogContentText>
          </DialogContent>

          <DialogActions>
            <Button
              className="border"
              sx={{ color: 'white' }}
              //   onClick={() => requestAccount()}
              autoFocus
            >
              Add
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
};

export default AddPecu;
