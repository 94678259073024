import * as actionTypes from './actionTypes';

const initialState = {
  PecuPrice: 0,
  UserInfo: null,
  WalletInfo: null,
  isAdmin: false,
  cryptoData: [],
  projectTokens: [],
  wrapTokens: [],
  holdingTokens: [],
  liquidityPools: [],
  stocks: [],
  forex: [],
  commodities: [],
  derivatives: [],
  dat: [],
  synthetics: [],
  syntheticsPrice: [],
  deListed: [],
  syntheticsList: [
    'hBTC',
    'hETH',
    'hXMR',
    'hBCH',
    'hLINK',
    'hUNIS',
    'hLTC',
    'hAVAX',
    'hPDOTN',
    'hSOL',
    'hMATIC',
    'hDOGE',
    'hADA',
    'hXRP',
    'hETC',
    'hBNB'
  ],
  derivativesList: [
    'hBNBBT',
    'hBNBET',
    'hBNBEU',
    'hBNBGB',
    'hBNBAU',
    'hBNBPT',
    'hBNBPD',
    'hPECUBT',
    'hPECUET',
    'hPECUGB',
    'hPECUEU',
    'hPECUAU',
    'hPECUPT',
    'hPECUPD',
    'hBTCEU',
    'hBTCGB',
    'hBTCAU',
    'hBTCPT',
    'hBTCPD',
    'hBTCWT',
    'hBTCBR',
    'hBTCET',
    'hLTCEU',
    'hLTCGB',
    'hLTCAU',
    'hLTCPT',
    'hLTCPD',
    'hLTCWT',
    'hLTCBR',
    'hLTCBT',
    'hLTCET',
    'hETHBT',
    'hETHBR',
    'hETHWT',
    'hETHPD',
    'hETHPT',
    'hETHAU',
    'hETHGB',
    'hETHEU',
    'hBJP',
    'hPJP',
    'hEJP',
    'hBTCH',
    'hECH',
    'hPCH',
    'hBHK',
    'hPHK',
    'hEHK',
    'hLHK',
    'hLJP',
    'hLCH',
    'hBCN',
    'hECN',
    'hLCN',
    'hPCN',
    'hBNZ',
    'hPNZ',
    'hENZ',
    'hLNZ',
    'hBAE',
    'hPAE',
    'hEAE',
    'hLAE',
    'hBJO',
    'hPJO',
    'hEJO',
    'hLJO',
    'hBIN',
    'hPIN',
    'hEIN',
    'hLIN',
    'hBSG',
    'hPSG',
    'hESG',
    'hLSG',
    'hBMX',
    'hPMX',
    'hEMX',
    'hLMX',
    'hBNO',
    'hPNO',
    'hENO',
    'hLNO',
    'hBSE',
    'hPSE',
    'hESE',
    'hLSE',
    'hBTW',
    'hPTW',
    'hETW',
    'hLTW',
    'hBHU',
    'hPHU',
    'hEHU',
    'hLHU',
    'hBTR',
    'hPTR',
    'hETR',
    'hLTR',
    'hBSA',
    'hPSA',
    'hESA',
    'hLSA',
    'hBEG',
    'hPEG',
    'hEEG',
    'hLEG',
    'hBGH',
    'hPGH',
    'hEGH',
    'hLGH',
    'hBUA',
    'hPUA',
    'hEUA',
    'hLUA',
    'hBPH',
    'hPPH',
    'hEPH',
    'hLPH',
    'hBMY',
    'hPMY',
    'hEMY',
    'hLMY',
    'hBKR',
    'hPKR',
    'hEKR',
    'hLKR',
    'hBID',
    'hPID',
    'hEID',
    'hLID',
    'hBIL',
    'hPIL',
    'hEIL',
    'hLIL',
    'hBTH',
    'hPTH',
    'hETTH',
    'hLTH',
    'hBPL',
    'hPPL',
    'hEPL',
    'hLPL'
  ],
  projectList: [
    'MMFx',
    'MLABx',
    'GYKRx',
    'LARSx',
    'MLRx',
    'BECKx',
    'PHARx',
    'DUMAx',
    'ALLEx'
  ],
  holdingList: ['MLABh', 'SPARh', 'NRLh', 'VSIOh', 'ARLNh', 'ISNOh', 'YUZUh'],
  tokenList: {}
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.PECU_PRICE:
      return { ...state, PecuPrice: action.price };
    case actionTypes.CRYPTO_DATA:
      return { ...state, CryptoData: action.cryptoData };
    case actionTypes.FETCH_HOLDING_TOKENS:
      return { ...state, holdingTokens: action.holdingTokens };
    case actionTypes.FETCH_PROJECT_TOKENS:
      return { ...state, projectTokens: action.projectTokens };
    case actionTypes.FETCH_WRAP_TOKENS:
      return { ...state, wrapTokens: action.wrapTokens };
    case actionTypes.FETCH_STOCKS:
      return { ...state, stocks: action.stocks };
    case actionTypes.FETCH_COMMODITY:
      return { ...state, commodities: action.commodities };
    case actionTypes.FETCH_FOREX:
      return { ...state, forex: action.forex };
    case actionTypes.FETCH_DERIVATIVES:
      return { ...state, derivatives: action.derivatives };
    case actionTypes.FETCH_DAT:
      return { ...state, dat: action.dat };
    case actionTypes.FETCH_SYNTHETICS:
      return { ...state, synthetics: action.synthetics };
    case actionTypes.FETCH_SYNTHETICS_PRICE:
      return { ...state, syntheticsPrice: action.syntheticsPrice };
    case actionTypes.FETCH_DELISTED_TOKENS:
      return { ...state, deListed: action.deListed };
    case actionTypes.FETCH_LIQUIDITY_POOLS:
      return { ...state, liquidityPools: action.liquidityPools };
    case actionTypes.FETCH_USER_INFO:
      return { ...state, UserInfo: action.UserInfo };
    case actionTypes.WALLET_INFO:
      return { ...state, WalletInfo: action.WalletInfo };
    case actionTypes.USER_INFO:
      return { ...state, UserInfo: action.UserInfo };
    case actionTypes.IS_ADMIN:
      return { ...state, isAdmin: action.isAdmin };
    case actionTypes.FETCH_TOKEN_LIST:
      return { ...state, tokenList: action.tokenList };
    default:
      return state;
  }
};

export default reducer;
