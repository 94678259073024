import React, { useEffect } from 'react';

const AuthReader = () => {
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    const validator = urlParams.get('validator');

    if (validator && token) {
      const base64DataProfile = atob(token);
      const base64DataWallet = atob(validator);
      if (
        !!base64DataWallet &&
        base64DataWallet !== 'null' &&
        base64DataWallet !== null
      ) {
        localStorage.setItem('hootdex_secretcookie_wallet', base64DataWallet);
      }
      if (
        !!base64DataProfile &&
        base64DataProfile !== 'null' &&
        base64DataProfile !== null
      ) {
        localStorage.setItem('hootdex_secretcookie', base64DataProfile);
      }

      const redirectUrl = `https://app.hootdex.net/hBTC`;
      window.location.href = redirectUrl;
    }
  }, []);

  return (
    <div
      style={{
        minHeight: '90vh',
        minWidth: '80vw',
        color: 'white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <h1>Please wait , Authenticating ...</h1>
    </div>
  );
};

export default AuthReader;
