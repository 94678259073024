import React, { useEffect, useState } from 'react';
import './style.css';
import { Link, useLocation } from 'react-router-dom';
import logo from '../../assets/images/logo.png';

import ConnectWallet from '../Modal/ConnectWallet';
import {
  AppBar,
  Avatar,
  Box,
  Button,
  ClickAwayListener,
  div,
  Grid,
  Modal,
  Paper,
  Tab,
  Tabs,
  Typography
} from '@mui/material';
import { api as axios } from '../../api';
import url from '../../serverUrl';
import { ethers } from 'ethers';
import HeaderTab from '../Tables/Exchange/HeaderTab';
import { useSelector } from 'react-redux';
export default function ExchangeNav({ fetchWallet, wallet }) {
  const [user, setUser] = useState(null);
  const [open, setOpen] = useState(false);
  const { UserInfo, WalletInfo, isAdmin } = useSelector((state) => state);

  const handleOpen = () => {
    const redirectUrl = `https://hootdex.net/RedirectAuth?source=app.hootdex.net`;
    window.location.href = redirectUrl;
  };

  const transactionParameters = {
    nonce: '0x00',
    gasPrice: '0x09184e72a000',
    gas: '0x2710',
    to: '0x80c2c0cF5A48010bd76485aD50e855f3D3Aac94E',
    from: window?.ethereum?.selectedAddress,
    value: '0x00',
    data: '0x7f7465737432000000000000000000000000000000000000000000000000000000600057',
    chainId: '0x3'
  };

  const txHash = async () =>
    await window.ethereum.request({
      method: 'eth_sendTransaction',
      params: [transactionParameters]
    });

  const [opens, setOpens] = React.useState(false);
  const handleOpens = () => setOpens(true);
  const handleClose = () => setOpen(false);

  const [showSugesstion, setShowSugesstion] = useState(false);
  const [currentValue, setCurrentValue] = useState(null);
  const findUser = async () => {
    if (UserInfo) {
      setUser(UserInfo);
    }
  };
  const get_current_index_coin = () => {
    axios
      .get(`${url}/wallet/get_current_index_coin`)
      .then((res) => {
        setCurrentValue(res.data[0].value);
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  const get_latest_block = () => {
    axios
      .get(`${url}/wallet/latestSyncedBlock`)
      .then((res) => {
        setBlock(res.data.block_length);
      })
      .catch((err) => {
        // console.log(err);
      });
  };
  const location = useLocation();
  useEffect(() => {
    setUser(null);
    findUser();
    get_current_index_coin();
  }, [location]);

  useEffect(() => {
    findUser();
  }, []);
  const [searchKey, setSearchKey] = useState('');
  const handleSubmit = (e) => {
    e.preventDefault();
    // if (searchKey) {
    //   fetchToken(searchKey);
    // } else fetchToken("all");
  };
  const [loading, setLoading] = useState(false);
  const [tokens, setTokens] = useState([]);
  const [block, setBlock] = useState('');
  const fetchToken = (target) => {
    if (target === 'all') {
      setLoading(true);
      axios
        .get(`${url}/hootdex/available-tokens`)
        .then((res) => {
          setTokens(res.data);

          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    } else {
      setLoading(true);
      setTokens(tokens.filter((each) => each.symbol === target));
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchToken('all');
    get_latest_block();
  }, []);
  const [showMore, setShowMore] = useState(false);
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const [showMenu, setShowMenu] = useState(false);
  const [walletAddress, setWalletAddress] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);
  const [defaultAccount, setDefaultAccount] = useState(null);
  const [userBalance, setUserBalance] = useState(null);
  const [privateKey, setPrivateKey] = useState(null);
  const [connButtonText, setConnButtonText] = useState('Connect Wallet');

  const requestAccount = () => {
    if (window.ethereum) {
      window.ethereum
        .request({
          method: 'eth_requestAccounts'
        })
        .then((result) => {
          accountChangeHandler(result[0]);
        });
    } else {
      setErrorMessage('Install MetaMask');
    }
  };
  const accountChangeHandler = (newAccount) => {
    setDefaultAccount(newAccount);
    getUserBalance(newAccount);
    getPrivateKey(newAccount);
    //// console.log(newAccount, 'newAccount');
  };
  const getUserBalance = (address) => {
    window.ethereum
      .request({
        method: 'eth_getBalance',
        params: [address.toString(), 'latest']
      })
      .then((balance) => {
        setUserBalance(ethers.formatEther(balance));
        // console.log(balance, 'balance');
      });
  };
  const getPrivateKey = (key) => {
    window.ethereum
      .request({
        method: 'eth_privateKeyToAccount',
        params: [key, 'latest']
      })
      .then((keys) => {
        setPrivateKey(keys);
        // console.log(keys, 'keys');
      });
  };

  const [time, setTime] = useState(new Date().toLocaleTimeString());
  useEffect(() => {
    setInterval(() => {
      setTime(new Date().toLocaleTimeString());
    }, 1000);
  }, []);
  return (
    <>
      {screenSize > 700 ? (
        <>
          <Grid className="Grid_Column">
            <section className="Header_Tab">
              <Box className="Header_TabPanel">
                <Link to="/">
                  {' '}
                  <img
                    src={require('../Tables/Exchange/hootdex.png')}
                    style={{ width: '150px' }}
                    alt="logo"
                  />
                </Link>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                  }}
                  className="res-nav"
                >
                  <div style={{ display: 'flex', gap: '10px' }}>
                    {true ? (
                      <>
                        {WalletInfo ? (
                          <Button
                            sx={{
                              display: { xs: 'none', md: 'inline-block' },
                              color: 'white',
                              textTransform: 'capitalize'
                            }}
                            onClick={() => {
                              localStorage.removeItem(
                                'hootdex_secretcookie_wallet'
                              );
                              fetchWallet();
                            }}
                          >
                            Disconnect Wallet
                          </Button>
                        ) : (
                          <Tabs aria-label="basic tabs example">
                            <Tab
                              label={'Connect Wallet'}
                              onClick={handleOpen}
                              className="headerTab"
                            />
                          </Tabs>
                        )}
                        <Link to="/dashboard" style={{ fontSize: '0.875rem' }}>
                          {' '}
                          <Tabs aria-label="basic tabs example">
                            <Tab label={'Dashboard'} className="headerTab" />
                          </Tabs>
                        </Link>
                      </>
                    ) : (
                      <Link to="/login">
                        {' '}
                        <Tabs aria-label="basic tabs example">
                          <Tab
                            label={'Login'}
                            onClick={handleOpen}
                            className="headerTab"
                          />
                        </Tabs>
                      </Link>
                    )}
                  </div>
                </div>
                <Box
                  className="headerTab_Accordion"
                  sx={{
                    borderBottom: 1,
                    borderColor: 'divider',
                    width: '100%',
                    backgroundColor: 'rgb(33, 36, 43)',
                    display: 'flex',
                    alignItems: 'baseline',
                    justifyContent: 'space-between'
                  }}
                >
                  {' '}
                  <Tabs aria-label="basic tabs example">
                    {[
                      { label: 'Markets', value: 'derivatives' },
                      { label: 'Swap', value: 'the-mvault' },
                      { label: 'Options', value: 'getting-started' },
                      { label: 'Liquidity Pools', value: 'nfts' },
                      { label: 'Tools', value: 'faq' },
                      { label: 'Feed', value: 'faq' }
                    ].map((e, i) => (
                      <a href={`#`}>
                        {' '}
                        <Tab label={e.label} key={i} className="headerTab" />
                      </a>
                    ))}
                  </Tabs>
                  {/* <HeaderTab /> */}
                </Box>
              </Box>
              <Box className="Header_Button">
                <button className="optionSetting">{time}</button> <HeaderTab />
              </Box>
            </section>
          </Grid>
        </>
      ) : (
        <div container className="nav">
          {' '}
          <span>
            <Link to="/" className="logo__header">
              <Box sx={{ width: { xs: 100, sm: 150, md: 180 } }}>
                <img src={logo} alt="nav_logo" width={'100%'} />
              </Box>
            </Link>
            <div
              style={{
                display: 'flex',
                alignItems: 'flex-start',
                flexDirection: 'column',
                justifyContent: 'center'
              }}
            >
              <div
                style={{
                  backgroundColor: 'rgba(255, 255, 255, 0.3)',
                  padding: '2px',
                  borderRadius: '5px',
                  color: 'white',
                  fontSize: '12px',
                  fontWeight: '600',
                  opacity: '60%',
                  margin: '0 5px 0 3px',
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <p>Latest synced block: {block}</p>
                <div
                  style={{
                    backgroundColor: 'yellow',
                    width: '10px',
                    height: '10px',
                    borderRadius: '10px',
                    margin: '0 4px 0 4px'
                  }}
                ></div>
              </div>
              <small
                style={{
                  color: 'white',
                  fontSize: '11px',
                  fontWeight: 'bold',
                  opacity: '60%',
                  margin: '0 5px 0 3px'
                }}
                className="res-margin"
              >
                PECU PRICE : {currentValue?.toFixed(5).toLocaleString('en-US')}{' '}
                USD
              </small>
            </div>
          </span>
          <button
            onClick={() => {
              setShowMenu((old) => !old);
            }}
            className="hamberger-icon"
          >
            ☰
          </button>
        </div>
      )}
      {showMenu ? (
        <div className="menu">
          {' '}
          {true ? (
            <>
              {WalletInfo && WalletInfo.uid ? (
                <Link to="">
                  <Button
                    sx={{
                      display: { xs: 'none', md: 'inline-block' },
                      color: 'white',
                      textTransform: 'capitalize'
                    }}
                    onClick={() => {
                      localStorage.removeItem('hootdex_secretcookie_wallet');
                      fetchWallet();
                    }}
                  >
                    Disconnect Wallet
                  </Button>
                </Link>
              ) : (
                <Button
                  sx={{
                    display: { xs: 'none', sm: 'inline-block' },
                    color: 'white',
                    textTransform: 'capitalize',
                    mb: 1
                  }}
                  onClick={handleOpen}
                >
                  Connect Wallet
                </Button>
              )}
              <Link to="/dashboard">
                {' '}
                <Button
                  sx={{
                    // display: { xs: 'none', md: 'inline-block' },
                    color: 'white',
                    textTransform: 'capitalize'
                    // m: 1,
                  }}
                >
                  Dashboard
                </Button>
              </Link>
            </>
          ) : (
            <Link to="/login">
              {' '}
              <Button
                sx={{
                  color: 'white',
                  textTransform: 'capitalize'
                  // m: 1,
                }}
              >
                Login
              </Button>
            </Link>
          )}
          <a
            target={'_blank'}
            href="https://www.hootdex.com/ecosystem/"
            rel="noreferrer"
          >
            {' '}
            <Button
              sx={{
                color: 'white',
                textTransform: 'capitalize'
                // m: 1,
              }}
            >
              Ecosystem
            </Button>
          </a>
          <a
            target={'_blank'}
            href="https://www.hootdex.com/cryptoPairs/"
            rel="noreferrer"
          >
            {' '}
            <Button
              sx={{
                color: 'white',
                textTransform: 'capitalize'
                // m: 1,
              }}
            >
              Derivatives
            </Button>
          </a>
          <a
            target={'_blank'}
            href="https://www.hootdex.com/the-mvault/"
            rel="noreferrer"
          >
            {' '}
            <Button
              sx={{
                color: 'white',
                textTransform: 'capitalize'
                // m: 1,
              }}
            >
              The MVault
            </Button>
          </a>
          <a
            target={'_blank'}
            href="https://www.hootdex.com/getting-started/"
            rel="noreferrer"
          >
            {' '}
            <Button
              sx={{
                color: 'white',
                textTransform: 'capitalize'
                // m: 1,
              }}
            >
              Getting Started
            </Button>
          </a>
          <a
            target={'_blank'}
            href="https://www.hootdex.com/nft-staking/"
            rel="noreferrer"
          >
            {' '}
            <Button
              sx={{
                color: 'white',
                textTransform: 'capitalize'
                // m: 1,
              }}
            >
              NFTs
            </Button>
          </a>
          <a
            href="https://www.hootdex.com/faq"
            target={'_blank'}
            rel="noreferrer"
          >
            {' '}
            <Button
              sx={{
                color: 'white',
                textTransform: 'capitalize'
                // m: 1,
              }}
            >
              FAQ
            </Button>
          </a>
        </div>
      ) : null}

      <ConnectWallet
        setOpen={setOpen}
        open={open}
        fetchWallet={fetchWallet}
        wallet={WalletInfo}
        setWalletAddress={setWalletAddress}
        requestAccount={requestAccount}
        walletAddress={walletAddress}
        privateKey={privateKey}
      />
    </>
  );
}
