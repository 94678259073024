import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import url from '../../../serverUrl';
import { api as axios } from '../../../api';
import { useState } from 'react';
import { useEffect } from 'react';
import symbols from '../../../symbolsMapper';
import { useSelector } from 'react-redux';

export default function MyTradeTable({ trade }) {
  const { WalletInfo } = useSelector((state) => state);
  const [marketTrade, setMarketTrade] = useState([]);
  const [user, setUser] = useState();
  useEffect(() => {
    if (WalletInfo && WalletInfo !== 'null' && WalletInfo !== null) {
      setUser(WalletInfo);
    }
  }, []);

  const getMyTrades = (id) => {
    if (id) {
      axios
        .get(`${url}/crypto/myTrade?user=${id}`)
        .then(async (res) => {
          if (res.data) {
            setMarketTrade(res.data);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const deleteOrder = (id) => {
    axios
      .delete(`${url}/crypto/limit-orders/${id}`)
      .then((res) => {
        getMyTrades(user?.pub_key);
        alert(JSON.stringify(res.data));
      })
      .catch((err) => alert(JSON.stringify(err)));
  };

  useEffect(() => {
    if (user && user.pub_key) {
      getMyTrades(user?.pub_key);
    }
  }, [user]);

  return (
    <TableContainer
      component={Paper}
      style={{
        backgroundColor: 'rgb(43, 49, 57)',
        maxHeight: '460px',
        overflowY: 'auto'
      }}
    >
      <Table stickyHeader size="small">
        <TableBody className="tbody-table">
          <TableRow hover>
            <TableCell style={{ color: 'white', fontWeight: 'bold' }}>
              {'Symbol'}
            </TableCell>
            <TableCell style={{ color: 'white', fontWeight: 'bold' }}>
              {'Price'}
            </TableCell>
            <TableCell style={{ color: 'white', fontWeight: 'bold' }}>
              {'Amount'}
            </TableCell>
            {/* <TableCell style={{ color: 'white', fontWeight: 'bold' }}>
              {'Total'}
            </TableCell> */}

            <TableCell style={{ color: 'white', fontWeight: 'bold' }}>
              {'Timestamp'}
            </TableCell>
          </TableRow>
          <h3 style={{ margin: '1rem', color: 'white' }}>
            {' '}
            Executed Swap Orders
          </h3>

          {marketTrade?.executedMarketOrders?.map((row) => (
            <>
              <TableRow hover key={row.pos}>
                <TableCell
                  style={{
                    color: 'orange',
                    fontWeight: 'bold',
                    fontSize: '11px'
                  }}
                >
                  {symbols[row.symbol] ?? row.symbol}
                </TableCell>

                <TableCell
                  style={{
                    fontSize: '11px',
                    color: 'green'
                  }}
                >
                  {row.price?.toFixed(5)}
                </TableCell>
                <TableCell
                  style={{
                    fontSize: '11px'
                  }}
                >
                  {Math.abs(row.quantity)?.toFixed(5)}
                </TableCell>
                {/* <TableCell style={{ color: 'white', fontSize: '11px' }}>
                  {(Math.abs(row.quantity) * row.price)?.toFixed(5)}
                </TableCell> */}
                <TableCell
                  style={{
                    fontSize: '11px'
                  }}
                >
                  {row.timestamp}
                  <br></br>
                </TableCell>
              </TableRow>
            </>
          ))}
          {marketTrade?.executedLimitOrders?.map((row) => (
            <>
              <TableRow hover key={row.pos}>
                <TableCell
                  style={{
                    color: 'orange',
                    fontWeight: 'bold',
                    fontSize: '11px'
                  }}
                >
                  {symbols[row.symbol] ?? row.symbol}
                </TableCell>

                <TableCell
                  style={{
                    fontSize: '11px',
                    color: 'green'
                  }}
                >
                  {row.price?.toFixed(5)}
                </TableCell>
                <TableCell
                  style={{
                    fontSize: '11px'
                  }}
                >
                  {Math.abs(row.quantity)?.toFixed(5)}
                </TableCell>
                {/* <TableCell style={{ color: 'white', fontSize: '11px' }}>
                  {(Math.abs(row.quantity) * row.price)?.toFixed(5)}
                </TableCell> */}
                <TableCell
                  style={{
                    fontSize: '11px'
                  }}
                >
                  {row.timestamp}
                  <br></br>
                </TableCell>
              </TableRow>
            </>
          ))}
          <h3 style={{ margin: '1rem', color: 'white' }}>
            Non Executed Limit Orders
          </h3>
          {marketTrade?.nonExecutedLimitOrders?.map((row) => (
            <>
              <TableRow hover key={row.pos}>
                <TableCell
                  style={{
                    color: 'orange',
                    fontWeight: 'bold',
                    fontSize: '11px'
                  }}
                >
                  {symbols[row.symbol] ?? row.symbol}
                </TableCell>

                <TableCell
                  style={{
                    fontSize: '11px',
                    color: 'green'
                  }}
                >
                  {row.price?.toFixed(5)}
                </TableCell>
                <TableCell
                  style={{
                    fontSize: '11px'
                  }}
                >
                  {Math.abs(row.quantity)?.toFixed(5)}
                </TableCell>
                {/* <TableCell style={{ color: 'white', fontSize: '11px' }}>
                  {(Math.abs(row.quantity) * row.price)?.toFixed(5)}
                </TableCell> */}
                <TableCell
                  style={{
                    fontSize: '11px'
                  }}
                >
                  {row.timestamp}
                  <br></br>
                </TableCell>
                <TableCell>
                  <button
                    style={{
                      fontSize: '11px',
                      backgroundColor: 'red',
                      margin: '1rem',
                      border: 'none',
                      color: 'white',
                      cursor: 'pointer'
                    }}
                    onClick={() => {
                      deleteOrder(row._id);
                    }}
                  >
                    {' '}
                    {'Cancel'}
                  </button>
                </TableCell>
              </TableRow>
            </>
          ))}

          <h3 style={{ margin: '1rem', color: 'white' }}>All Transaction</h3>
          {marketTrade?.transactions?.map((row) => (
            <>
              <TableRow hover key={row.pos}>
                <TableCell
                  style={{
                    color: 'orange',
                    fontWeight: 'bold',
                    fontSize: '11px'
                  }}
                >
                  {symbols[row.symbol] ?? row.symbol}
                </TableCell>

                <TableCell
                  style={{
                    fontSize: '11px',
                    color: 'green'
                  }}
                >
                  {row.price?.toFixed(5)}
                </TableCell>
                <TableCell
                  style={{
                    fontSize: '11px'
                  }}
                >
                  {Math.abs(row.amount)?.toFixed(5)}
                </TableCell>
                {/* <TableCell style={{ color: 'white', fontSize: '11px' }}>
                  {(Math.abs(row.quantity) * row.price)?.toFixed(5)}
                </TableCell> */}
                <TableCell
                  style={{
                    fontSize: '11px'
                  }}
                >
                  {new Date(row.timestamp)?.toLocaleString()}
                  <br></br>
                </TableCell>
              </TableRow>
            </>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
