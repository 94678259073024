import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import './Derivatives.css';
import CoinInfo from '../../coinInfo/coinInfo';
import { useSelector } from 'react-redux';
import { api as axios } from '../../../api';
import url from '../../../serverUrl';

function LeftPanel({ tokenData, tokenSymbol, lastBid, lastAsk }) {
  const defaultToken = tokenSymbol ? tokenSymbol : 'hBTC';
  const [token, setToken] = useState(defaultToken);
  const [tokenSelected, setTokenSelected] = useState({});
  const [last10Price, setLast10Price] = useState([]);
  const [isAskChanged, setIsAskChanged] = useState(false);
  const [isBidChanged, setIsBidChanged] = useState(false);

  const { derivatives, synthetics } = useSelector((state) => state);
  const [derivativesTokens, setDerivativesTokens] = useState([]);
  useEffect(() => {
    if (derivatives && derivatives.tokens) {
      setDerivativesTokens(derivatives.tokens);
    }
  }, [derivatives]);
  useEffect(() => {
    const selcted = [...synthetics, ...derivativesTokens].find(
      (e) => e.identitySymbol === token
    );
    setTokenSelected(selcted);
  }, [synthetics, derivatives]);

  useEffect(() => {
    setTokenSelected(tokenData);
    //fetch10price(tokenData?.identitySymbol);
    if (tokenData && tokenData.token_symbol) {
      setToken(tokenData?.token_symbol);
    }
    if (tokenData && tokenData.identitySymbol) {
      setToken(tokenData?.identitySymbol);
    }
  }, [tokenData]);

  const [bidData, setBidData] = useState([]);
  const [orderData, setOrderData] = useState([]);

  const fetchOrder = (token) => {
    axios
      .get(`${url}/hootdex/bid?symbol=${token}`)
      .then((res) => {
        setOrderData(res.data);
        lastAsk(res.data);
      })
      .catch((err) => console.log(err));
  };
  const fetchBid = (token) => {
    axios
      .get(`${url}/hootdex/order?symbol=${token}`)
      .then((res) => {
        setBidData(res.data);
        lastBid(res.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (tokenData && (tokenData.symbol || tokenData.token_symbol)) {
      const symbolLocal = tokenData?.symbol || tokenData?.token_symbol;
      fetchBid(symbolLocal);
      fetchOrder(symbolLocal);
    }
  }, [tokenData]);

  const [suggested, setSuggested] = useState([]);
  function searchHandler(searchString) {
    const filteredArray = [...derivativesTokens, ...synthetics].filter(
      function (element) {
        return (
          element.symbol
            .toLowerCase()
            .trim()
            .includes(searchString.toLowerCase()) ||
          element.identitySymbol
            .toLowerCase()
            .trim()
            .includes(searchString.toLowerCase()) ||
          element.name.toLowerCase().trim().includes(searchString.toLowerCase())
        );
      }
    );
    if (searchString !== '') {
      setSuggested(filteredArray.slice(0, 5)); // Return the first 5 matches
    } else {
      setSuggested([]);
    }
  }

  useEffect(() => {
    const intervalId = setInterval(() => {
      fetchDataAndUpdatePrice(tokenData);
    }, 2000);

    return () => {
      clearInterval(intervalId);
    };
  }, [tokenData]);

  const fetchDataAndUpdatePrice = async (tokenData) => {
    try {
      const symbolLocal = tokenData?.symbol || tokenData?.token_symbol;
      fetchBid(symbolLocal);
      fetchOrder(symbolLocal);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    setIsAskChanged(true);
    setTimeout(() => {
      setIsAskChanged(false);
    }, 2000);
  }, [orderData]);
  useEffect(() => {
    setIsBidChanged(true);
    setTimeout(() => {
      setIsBidChanged(false);
    }, 2000);
  }, [bidData]);

  return (
    <>
      {' '}
      <Grid item xs={12} md={4} lg={3}>
        <Paper
          className="Paper_block"
          sx={{
            // p: 2,
            display: 'flex',
            flexDirection: 'column',
            minHeight: '122vh',
            height: '100%',
            backgroundColor: 'rgb(33, 36, 43) !important',
            fontsize: '13px',
            fontWeight: '500',
            color: '#91a0b1 !important',
            margin: '0',
            padding: '16px 16px 0 16px'
          }}
        >
          <label className="paperStyle"> Search </label>
          <input
            type={'search'}
            style={{
              width: '99%',
              border: '1px solid black',
              padding: '8px',
              outline: 'none',
              backgroundColor: 'rgb(43, 49, 57)',
              color: 'white'
            }}
            onChange={(e) => {
              searchHandler(e.target.value);
            }}
          />
          {suggested.map((e, i) => {
            return <li key={i}>{e.symbol}</li>;
          })}
          <Divider sx={{ backgroundColor: 'black' }} />
          <CoinInfo
            coin={{
              id:
                token === 'BTC'
                  ? 'bitcoin'
                  : token == 'ETH'
                  ? 'ethereum'
                  : token == 'USDT'
                  ? 'tether'
                  : '',
              symbol: tokenData?.symbol || tokenData?.token_symbol
            }}
            style={{
              color: 'orange',
              backgroundColor: '#01402b',
              fontSize: '10px',
              opacity: '1',
              padding: '5px',
              height: '30px ! important'
            }}
          />
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-evenly'
            }}
          >
            <div>
              {' '}
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  fontSize: '14px',
                  margin: '5px 0 5px 0',
                  border: '1px solid orange',
                  padding: '5px',
                  textAlign: 'left'
                }}
              >
                {' '}
                <span style={{ width: '70px' }}>{`Price`}</span>
                <span
                  style={{ width: '70px', textAlign: 'right' }}
                >{`Amount `}</span>
                <span
                  style={{ width: '70px', textAlign: 'right' }}
                >{`Total `}</span>
              </div>
              {bidData.map((item, index) => (
                <div
                  key={item.id}
                  style={{
                    minWidth: '250px',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    fontSize: '12px',
                    textAlign: 'left',
                    color: index === 0 && isBidChanged ? 'red' : ''
                  }}
                >
                  {' '}
                  <span
                    style={{
                      width: '70px',
                      color: 'red'
                    }}
                  >
                    {item.price?.toFixed(5)}
                  </span>
                  <span style={{ width: '70px', textAlign: 'right' }}>
                    {Math.abs(item.quantity)?.toFixed(5)}
                  </span>
                  <span style={{ width: '90px', textAlign: 'right' }}>
                    {Math.abs(item.quantity * item.price)?.toFixed(5)}
                  </span>
                </div>
              ))}
            </div>{' '}
            <div>
              {' '}
              <div
                style={{
                  minWidth: '250px',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  fontSize: '14px',
                  margin: '5px 0 5px 0',
                  border: '1px solid orange',
                  padding: '5px'
                }}
              >
                <span style={{ width: '70px' }}>{`Price`}</span>
                <span
                  style={{ width: '70px', textAlign: 'right' }}
                >{`Amount `}</span>
                <span
                  style={{ width: '70px', textAlign: 'right' }}
                >{`Total `}</span>
              </div>
              {orderData.map((item, index) => (
                <div
                  key={item.id}
                  style={{
                    minWidth: '250px',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    fontSize: '12px',
                    textAlign: 'left',
                    color: index === 0 && isAskChanged ? 'green' : ''
                  }}
                >
                  <span
                    style={{
                      width: '70px',
                      color: 'green'
                    }}
                  >
                    {item.price?.toFixed(5)}
                  </span>
                  <span
                    style={{
                      width: '70px',
                      textAlign: 'right'
                    }}
                  >
                    {Math.abs(item.quantity)?.toFixed(5)}
                  </span>
                  <span style={{ width: '90px', textAlign: 'right' }}>
                    {Math.abs(item.quantity * item.price)?.toFixed(5)}
                  </span>
                  {/* <span style={{ width: '70px', textAlign: 'right' }}>
                    {new Date(item.createdAt).toLocaleTimeString()}
                  </span> */}
                </div>
              ))}
            </div>
          </div>

          <Divider sx={{ backgroundColor: 'black', marginTop: '1rem' }} />
          <label className="paperStyle"> Top Movers</label>
          <Divider sx={{ backgroundColor: 'black' }} />
          <Box
            className="contract-Price"
            style={{
              minHeight: '100px'
            }}
          ></Box>
          <Divider sx={{ backgroundColor: 'black' }} />

          <Box className="contract-Price"></Box>
        </Paper>
      </Grid>
    </>
  );
}
export default React.memo(LeftPanel);
