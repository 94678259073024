import './App.css';
import Home from './screens/home';
import Nav from './components/nav/nav';
import Footer from './components/footer/footer';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Login from './screens/login';
import { useCallback, useEffect, useState } from 'react';
import DashboardIndex from './screens/DashboardIndex';
import PoolPage from './modules/Pools/pools.page';
import { api as axios } from './api';
import url from './serverUrl';
import CreatePool from './screens/CreatePool';
import ProjectToken from './modules/Projects/project.page';
import HoldingTokenPage from './screens/holdingTokenPage';
import { useDispatch, useSelector } from 'react-redux';
import * as actionTypes from './store/actionTypes';
import {
  fetchProjectTokens,
  fetchWrapTokens,
  fecthHoldingTokens,
  fetchCommodity,
  fetchDerivatives,
  fetchSynthetics,
  fetchTokenList
} from './utils';
import StockPage from './modules/Stocks/stocks.page';
import Derivatives from './components/Tables/Exchange/Derivatives';
import ForexPage from './modules/Forex/forex.page';
import News from './screens/news';
import Commodity from './components/Tables/commodity';
import CommodityPage from './screens/commodityPAge';
import SyntheticPage from './modules/Synthetics/synthetics.page';
import PoolTokensUpdate from './components/Tables/editpools';
import DelistedToken from './components/Tables/DelistedToken';
import ExchangeNav from './components/nav/exchangeNav';
import RedirectPage from './authExchange';
import AuthReader from './authReader';
import PoolsTable from './modules/Pools/pools.table';
import ForexTable from './modules/Forex/forex.table';
import CreateDAT from './modules/DAT/createDAT';
import DatTable from './modules/DAT/dat.table';
import DatPage from './modules/DAT/dat.page';
import StocksTable from './modules/Stocks/stocks.table';
import ProjectTable from './modules/Projects/project.table';
import CryptopairTable from './modules/CryptoPair/cryptopair.table';
import CryptoPairPage from './modules/CryptoPair/cryptopair.page';
import WrapTable from './modules/Wraps/wrap.table';
import WrapPage from './modules/Wraps/wrap.page';

function App() {
  const { UserInfo, WalletInfo, isAdmin } = useSelector((state) => state);
  const [pecuCoins, setPecuCoins] = useState({});
  const dispatch = useDispatch();

  const fetchWallet = useCallback(async () => {
    if (WalletInfo && WalletInfo.userFound) {
      try {
        const response = await axios.get(
          `${url}/hootdex/getMycoins/${WalletInfo.uid}`
        );
        setPecuCoins(response.data[0]);
      } catch (error) {
        console.error('Error fetching wallet:', error);
      }
    }
  }, [WalletInfo]);

  useEffect(() => {
    if (WalletInfo && WalletInfo.userFound) {
      fetchWallet();
    }
  }, [WalletInfo, fetchWallet]);

  const PecuPriceHttpRequest = useCallback(() => {
    axios
      .get(`${url}/wallet/get_current_index_coin`)
      .then((res) => {
        const price = res.data[0].value;
        dispatch({ type: actionTypes.PECU_PRICE, price: price });
      })
      .catch((err) => console.log(err));
  }, [dispatch]);

  const get_crypto_Data = useCallback(() => {
    axios.get(`${url}/crypto/index`).then((res) => {
      dispatch({ type: actionTypes.CRYPTO_DATA, cryptoData: res.data });
    });
  }, [dispatch]);

  useEffect(() => {
    PecuPriceHttpRequest();
    get_crypto_Data();
  }, [PecuPriceHttpRequest, get_crypto_Data]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      PecuPriceHttpRequest();
      get_crypto_Data();
    }, 60000);
    return () => {
      clearInterval(intervalId);
    };
  }, [PecuPriceHttpRequest, get_crypto_Data]);

  const isExhange = process.env.REACT_APP_IS_EXCHANGE === 'true';
  const isHootdex = !isExhange;

  useEffect(() => {
    if (isExhange) {
      // fetchWrapTokens(dispatch, actionTypes.FETCH_WRAP_TOKENS);
      // fetchCommodity(dispatch, actionTypes.FETCH_COMMODITY);
      // fetchSynthetics(dispatch, actionTypes.FETCH_SYNTHETICS);
      // fetchDerivatives(dispatch, actionTypes.FETCH_DERIVATIVES, 1, 160);
      // fetchProjectTokens(dispatch, actionTypes.FETCH_PROJECT_TOKENS);
      // fecthHoldingTokens(dispatch, actionTypes.FETCH_HOLDING_TOKENS);
      fetchTokenList(dispatch, actionTypes.FETCH_TOKEN_LIST);
    }
  }, [dispatch, isExhange]);
  useEffect(() => {
    const walletExist = localStorage.getItem('hootdex_secretcookie_wallet');
    const userExist = localStorage.getItem('hootdex_secretcookie');
    if (walletExist && walletExist !== null && walletExist !== 'null') {
      const walletData = JSON.parse(walletExist);
      dispatch({ type: actionTypes.WALLET_INFO, WalletInfo: walletData });
    }

    if (userExist && userExist !== null && userExist !== 'null') {
      const userData = JSON.parse(userExist);
      const admin = userData.tier === 0;
      dispatch({ type: actionTypes.USER_INFO, UserInfo: userData });
      dispatch({ type: actionTypes.IS_ADMIN, isAdmin: admin });
    }
  }, []);
  return (
    <BrowserRouter>
      {isHootdex && (
        <>
          {' '}
          <Nav wallet={WalletInfo} fetchWallet={fetchWallet} />
          <Routes>
            <Route path="/create-pool" element={<CreatePool />} />
            {isAdmin && <Route path="/createDBT" element={<CreateDAT />} />}

            <Route path="/" element={<Home />} />

            <Route path="/Tokens" element={<Home />} />
            <Route path="/AllPools" element={<PoolsTable />} />

            <Route
              path="/pools/:id"
              element={<PoolPage user={UserInfo} pecuCoins={pecuCoins} />}
            />

            <Route
              path="updatePoolsAdmin"
              element={
                <PoolTokensUpdate user={UserInfo} pecuCoins={pecuCoins} />
              }
            />

            <Route
              path="/tokens/:tokenSymbol"
              element={<WrapPage user={UserInfo} pecuCoins={pecuCoins} />}
            />

            <Route path="/Projects" element={<ProjectTable />} />

            <Route
              path="/DelistedToken"
              element={<DelistedToken user={UserInfo} pecuCoins={pecuCoins} />}
            />

            <Route
              path="/stock/:symbol"
              element={<StockPage user={UserInfo} pecuCoins={pecuCoins} />}
            />
            <Route
              path="/forex/:identitySymbol"
              element={<ForexPage user={UserInfo} pecuCoins={pecuCoins} />}
            />
            <Route
              path="/ccpairs/:symbol/:pair"
              element={<CommodityPage user={UserInfo} pecuCoins={pecuCoins} />}
            />
            <Route
              path="/holding-token/:tokenSymbol"
              element={
                <HoldingTokenPage user={UserInfo} pecuCoins={pecuCoins} />
              }
            />
            <Route
              path="/project-token/:tokenSymbol"
              element={<ProjectToken user={UserInfo} pecuCoins={pecuCoins} />}
            />

            <Route
              path="/synthetics/:identitySymbol"
              element={<SyntheticPage user={UserInfo} pecuCoins={pecuCoins} />}
            />
            <Route
              path="/cryptoPairs/:identitySymbol"
              element={<CryptoPairPage user={UserInfo} pecuCoins={pecuCoins} />}
            />
            <Route
              path="/dbt/:identitySymbol"
              element={<DatPage user={UserInfo} pecuCoins={pecuCoins} />}
            />
            <Route
              path="/Stocks"
              element={<StocksTable user={UserInfo} pecuCoins={pecuCoins} />}
            />
            <Route path="/News" element={<News />} />
            <Route path="/Forex" element={<ForexTable />} />

            <Route path="/cryptoPairs" element={<CryptopairTable />} />

            <Route path="/dbt" element={<DatTable />} />

            <Route path="/ccpairs" element={<Commodity />} />
            <Route path="/pools" element={<PoolsTable />} />
            <Route path="/wrapTokens" element={<WrapTable />} />
            <Route path="/login" element={<Login />} />
            <Route
              path="/dashboard"
              element={
                UserInfo && UserInfo.loggedIn ? (
                  <DashboardIndex pecuCoins={pecuCoins} />
                ) : (
                  <Login />
                )
              }
            />
            <Route path="/RedirectAuth" element={<RedirectPage />} />
          </Routes>
        </>
      )}

      {isExhange && (
        <>
          {' '}
          <ExchangeNav wallet={WalletInfo} fetchWallet={fetchWallet} />
          <Routes>
            <Route path="/:symbol" element={<Derivatives />} />
            <Route path="/" element={<Derivatives />} />
            <Route path="/auth" element={<AuthReader />} />
            <Route
              path="/dashboard"
              element={
                UserInfo && UserInfo.loggedIn ? (
                  <DashboardIndex pecuCoins={pecuCoins} />
                ) : (
                  <Login />
                )
              }
            />
          </Routes>{' '}
        </>
      )}

      <Footer />
    </BrowserRouter>
  );
}

export default App;
