export const MenuData = [
  { label: 'TOKENS', path: '/' },
  { label: 'POOLS', path: '/AllPools' },
  { label: 'WRAPS', path: '/WrapTokens' },
  { label: 'STOCKS', path: '/Stocks' },
  { label: 'CryptoPairs', path: '/cryptoPairs' },
  { label: 'FxPairs', path: '/Forex' },
  { label: 'DBT', path: '/dbt' },
  { label: 'PROJECTS', path: '/projects' },
  { label: 'NEWS', path: '/News' },
  { label: 'DELISTED ', path: '/DelistedToken' }
];
